/* eslint-disable no-console */
import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import { AppContext } from "../../../../components/app-context";
import CustomLoadingOverlay from "../../../../commonUtil/components/loadingmask/CustomLoadingOverlay";
import { doesEmpty } from "../../../../commonUtil/utils/object";
import { applyCustomKeyNavigation } from "../../../../commonUtil/utils/keyNavigation";
import { toEmptyStringIfUndefined } from "../../../../commonUtil/utils/string";
import SequenceEditor from "../../../reusable/editors/SequenceEditor";
import ManageSequenceModal from "../../../reusable/ROSequences/ManageSequenceModal";
import {
  makeSecureRestApi,
  showBodyMask,
  hideBodyMask
} from "../../../../api/xmmAxios";
import Confirmation from "../../../../commonUtil/dialog/Confirmation";
import { toast } from "@cx/ui/Toast";
import { loadAgGridLocale } from "../../../../i18n/LocaleSender";
import { xlate } from "../../../../commonUtil/i18n/locales";
import * as formvalidator from "../../../formik/formvalidator";

class ROSequencesPage extends Component {
  static contextType = AppContext;

  constructor(props, context) {
    super(props, context);

    this.manageSequenceModalRef = React.createRef();

    // Bind grid functions in constructor
    this.getRowNodeId = this.getRowNodeId.bind(this);
    this.onCellClickedEvent = this.onCellClickedEvent.bind(this);
    this.onCellValueChanged = this.onCellValueChanged.bind(this);
    this.handleColumnResized = this.handleColumnResized.bind(this);
    this.updateStatusBox = this.updateStatusBox.bind(this);
    this.onFirstDataRendered = this.onFirstDataRendered.bind(this);
    this.handleSaveCellEdit = this.handleSaveCellEdit.bind(this);
    this.handleGridSizeChanged = this.handleGridSizeChanged.bind(this);
    this.openDeleteSequenceModal = this.openDeleteSequenceModal.bind(this);
    this.handleDeleteSequence = this.handleDeleteSequence.bind(this);
    this.onRowGroupOpened = this.onRowGroupOpened.bind(this);
    this.onSequenceSelected = this.onSequenceSelected.bind(this);
    this.openManageSequenceFromEditor =
      this.openManageSequenceFromEditor.bind(this);
    this.getColumnList = this.getColumnList.bind(this);

    const { localeStrings, enableDMSPlusContent } = context;

    this.initializeLocaleValues();

    this.groupList = [];
    const gridOptions = {
      searchKey: context.payTypesGrid.searchKey,
      payTypesGrid: context.payTypesGrid,
      editOption: null, // set values as edit, add, delete
      operationName: "",
      enableDMSPlusContent,
      // ag-grid props
      ROSequences: null, // should be null - fix to skip "No records found" msg on grid load.
      showManageModal: false,
      showDiscardEditPopup: false,
      selectedSequence: {},
      selectedSequenceName: "Default",
      groupDisplayType: "groupRows",
      groupDefaultExpanded: 1,
      groupRowRendererParams: {
        suppressCount: true
      },
      selectionlist: [],
      sequenceList: [],
      columnDefs: this.getColumnList(),
      selectedItem: {},
      defaultColDef: {
        floatingFilter: false, // true - enable column header filters
        sortable: true,
        resizable: true,
        editable: false, // default disable editor
        enableRowGroup: false,
        sortingOrder: ["asc", "desc", null],
        width: 120,
        autoHeight: true,
        filter: "agTextColumnFilter",
        felx: 1,
        getQuickFilterText: params => {
          if (!params.column.visible) {
            return null;
          } else {
            return params.value;
          }
        },
        headerComponentParams: {
          template: `
          <div class="ag-cell-label-container" role="presentation">
            <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
            <div ref="eLabel" class="ag-header-cell-label" role="presentation">
              <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
              <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
              <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>
              <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>
              <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>
            </div>
          </div>
          `
        },
        suppressKeyboardEvent: applyCustomKeyNavigation,
        rowGroup: false
      },
      multiSortKey: "ctrl",
      components: {},
      loadingOverlayComponent: CustomLoadingOverlay,
      loadingOverlayComponentParams: {
        loadingMessage: "Loading",
        isLoading: true,
        noRows: false
      },
      noRowsOverlayComponent: CustomLoadingOverlay,
      noRowsOverlayComponentParams: {
        noRowsMessage: xlate("xmm.portal.ro_sequences.empty_grid_msg"),
        isLoading: false,
        noRows: true
      },
      statusBar: {
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left"
          },
          {
            statusPanel: "agFilteredRowCountComponent"
          },
          {
            statusPanel: "agSelectedRowCountComponent",
            align: "left"
          }
        ]
      },
      // true - use browser default tooltip instead of ag-grid tooltip
      enableBrowserTooltips: true,
      columnTypes: {
        numberColumn: {
          maxWidth: 130,
          minWidth: 130,
          filter: "agNumberColumnFilter",
          filterParams: {
            includeBlanksInEquals: false,
            includeBlanksInLessThan: false,
            includeBlanksInGreaterThan: false,
            buttons: ["clear"]
          }
        },
        nonEditableColumn: { editable: false },
        noFilterColumn: {
          width: 100,
          columnGroupShow: "open",
          filter: false
        }
      },
      rowSelection: "multiple",
      isRowSelectable() {
        return true; // to see checkbox
        // return rowNode.data ? rowNode.data.make !== "ANY" : false;
      },
      localeText: loadAgGridLocale(localeStrings),
      sideBar: {
        hiddenByDefault: true
      }
    };
    this.state = gridOptions;
  }
  /**
   * Add event listeners
   * when an instance of a component is being created and inserted into the DOM
   */
  componentDidMount() {
    window.addEventListener(
      "saveCellEditEvent",
      this.handleSaveCellEdit,
      false
    );
    window.addEventListener(
      "openManageSequenceFromEditor",
      this.openManageSequenceFromEditor,
      false
    );
    window.addEventListener(
      "openDeleteSequenceModalFromManager",
      this.openDeleteSequenceModal,
      false
    );
    window.addEventListener(
      "onSequenceSelected",
      this.onSequenceSelected,
      false
    );
    this.loadSequenceNumbers();
  }
  /**
   * Remove event listeners
   * when a component is being removed from the DOM
   */
  componentWillUnmount() {
    this.saveGridState();
    window.removeEventListener(
      "saveCellEditEvent",
      this.handleSaveCellEdit,
      false
    );
    window.removeEventListener(
      "openManageSequenceFromEditor",
      this.openManageSequenceFromEditor,
      false
    );
    window.removeEventListener(
      "openDeleteSequenceModalFromManager",
      this.openDeleteSequenceModal,
      false
    );
    window.addEventListener(
      "onSequenceSelected",
      this.onSequenceSelected,
      false
    );
  }
  initializeLocaleValues() {
    this.pageTitle = xlate("xmm.portal.ro_sequences.page_title");
    this.deletingMsg = xlate("xmm.portal.common.deleting");
    this.deletedMsg = xlate("xmm.portal.common.deleted");
    this.deleteSequenceConfirmationMsg = xlate(
      "xmm.portal.ro_sequences.delete_sequence_confirmation_msg"
    );
    this.deletingErrorMsg = xlate("xmm.portal.errors.deleting");
    this.savingMsg = xlate("xmm.portal.common.saving");
    this.savedMsg = xlate("xmm.portal.common.saved");
    this.saveErrorMsg = xlate("xmm.portal.common.cannot_save_changes");
  }
  /* Action to save ag-grid {column, filter, pivot, sort} to local state
   */
  saveGridState() {
    const { searchKey } = this.state;
    if (this.gridApi && this.gridColumnApi) {
      const payTypesGrid = {
        colState: this.gridColumnApi.getColumnState(),
        pivotState: this.gridColumnApi.isPivotMode(),
        filterState: this.gridApi.getFilterModel(),
        searchKey
      };
      this.setState({
        payTypesGrid
      });
      this.context.setPayTypesGridState(payTypesGrid);
    }
  }
  /* This Util called to restore ag-grid controls,filters,sorters from app-context when re-visited page */
  restoreGridState() {
    const { colState, filterState, pivotState, searchKey } =
      this.state.payTypesGrid;
    if (colState && this.gridApi && this.gridColumnApi) {
      this.gridColumnApi.applyColumnState(colState);
      this.gridColumnApi.setPivotMode(pivotState);
      // this.gridApi.setSortModel(sortState);
      this.assignColumnState(colState);
      this.gridApi.setFilterModel(filterState);
      this.setState(
        {
          searchKey
        },
        () => {
          this.gridApi.setQuickFilter(searchKey);
        }
      );
    }
  }
  onFirstDataRendered() {
    this.restoreGridState();
    this.sizeToFit();
  }
  handleColumnResized = () => {
    // this.gridApi.resetRowHeights();
  };
  /* IMP - this function required for CRUD operations, to get RowNode */
  getRowNodeId(data) {
    return data.dealerServiceTypeId; // primary or unique key of record
  }
  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.loadGridData();
    this.gridApi.closeToolPanel();
  };
  sizeToFit() {
    this.gridApi && this.gridApi.sizeColumnsToFit();
  }
  handleGridSizeChanged = event => {
    const { clientWidth, clientHeight } = event;
    if (clientWidth && clientHeight) {
      this.sizeToFit();
    }
  };
  updateState(data) {
    if (data && data.length > 0) {
      this.gridApi && this.gridApi.hideOverlay();
      this.setState(
        {
          serviceTypes: data
        },
        () => {
          this.sizeToFit();
        }
      );
    } else {
      this.gridApi && this.gridApi.showNoRowsOverlay();
    }
  }
  loadGridData() {
    const { dealerCode } = this.context;
    const restUrl = `ops/proxyapi/oeproxy/rest/internal/dealerSvcTypeSeq/getDlrSvcTypeSeqNums/${dealerCode}`;
    showBodyMask();
    makeSecureRestApi(
      {
        url: restUrl,
        method: "get"
      },
      data => {
        if (data) {
          hideBodyMask();
          this.gridApi && this.gridApi.hideOverlay();
          this.updateState(data.data);
        }
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "There was an error while fetching data for this dealer.  Please try again later.";
        toast.error(msg, {
          closeOnClick: true
        });
        this.gridApi && this.gridApi.showNoRowsOverlay();
        hideBodyMask();
      }
    );
  }

  assignColumnState = defaultSortModel => {
    this.gridColumnApi &&
      this.gridColumnApi.applyColumnState({
        state: defaultSortModel,
        defaultState: {
          // important to say 'null' as undefined means 'do nothing'
          sort: null
        }
      });
  };
  callRefreshAfterMillis(params, gridApi) {
    setTimeout(function () {
      gridApi.refreshCells(params);
    }, 300);
  }
  /* "cellClicked" event handler fired on specific columns */

  onRowGroupOpened() {
    // this.gridApi.redrawRows();
    this.gridApi.onGroupExpandedOrCollapsed();
  }
  onCellClickedEvent(params) {}
  // This event fired after a cell has been changed with default editing
  onCellValueChanged(params) {
    if (
      toEmptyStringIfUndefined(params.oldValue) !==
      toEmptyStringIfUndefined(params.newValue)
    ) {
      if (this.validateField(params)) {
        this.clearFieldValidation(params);
        this.onSaveCellEdit(params);
      }
    }
  }

  handleSaveCellEdit = event => {
    event.preventDefault();
    const { data, field, value } = event.detail;
    this.savePayload({ data, colDef: { field } }, value);
  };
  onSaveCellEdit(params) {
    this.savePayload(params, params.value);
  }
  /* celledit handler to save edits */
  savePayload(params, sequence) {
    const url =
      "/ops/proxyapi/oeproxy/rest/internal/dealerSvcTypeSeq/updateDlrSvcTypeSeqNum";
    const payload = {
      dealerCode: params.dealerCode,
      make: params.make,
      dealerSequenceNumId: sequence.dealerSequenceNumId,
      dealerServiceTypeId: params.dealerServiceTypeId
    };
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };
    makeSecureRestApi(
      {
        url,
        method: "put",
        data: payload,
        headers
      },
      () => {
        params.dealerSequenceNumId = sequence.dealerSequenceNumId;
        this.updateStatusBox(this.savedMsg, "success", true);
        this.gridApi.stopEditing();        
        this.loadGridData();
      },
      error => {
        const errMsg = error["message"]
          ? error.message
          : xlate("xmm.portal.errors.save_data_error");
      }
    );
  }

  loadSequenceNumbers() {
    const { dealerCode } = this.context;
    const restUrl = `/ops/proxyapi/oeproxy/rest/internal/dealerSvcTypeSeq/getDlrSeqNums/${dealerCode}`;
    makeSecureRestApi(
      {
        url: restUrl,
        method: "get"
      },
      data => {
        if (data) {
          this.setState({ sequenceList: data.data });
          this.setState({ selectedSequence: this.findSequenceByName(this.state.sequenceList, this.state.selectedSequenceName) });
        }
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "There was an error while fetching data for this dealer.  Please try again later.";
        toast.error(msg, {
          closeOnClick: true
        });
      }
    );
  }
  confirmDeletePayType = dealerPayType => {
    this.setState({ dealerPayType, showDeleteConfirmation: true });
  };
  onSequenceSelected = event => {
    const { data, sequence } = event.detail;
    this.savePayload(data, sequence);
  };
  getColumnList() {
    const baseCols = [
      {
        headerName: "",
        field: "make",
        hide: true,
        rowGroup: true
      },
      {
        headerName: xlate("xmm.portal.ro_sequences.service_types"),
        editable: false,
        cellClass: "xmm-wrap-cell",
        field: "serviceTypeCode",
        cellRenderer: serviceTypeRenderer,
        sortingOrder: ["asc", "desc"],
        minWidth: 120,
        filter: "agSetColumnFilter",
        filterParams: {
          buttons: ["clear"]
        }
      },
      {
        headerName: xlate("xmm.portal.ro_sequences.sequence_name"),
        field: "sequenceName",
        cellEditorPopup: true,
        editable: true,
        cellEditor: SequenceEditor,
        cellEditorParams: {
          parentHandle: this
        },
        cellRenderer: sequenceNameRender,
        cellClass: "editable-triangle",
        minWidth: 120,
        maxWidth: 200,
        sortingOrder: ["asc", "desc"],
        filter: "agSetColumnFilter",
        filterParams: {
          buttons: ["clear"]
        }
      },
      {
        headerName: xlate("xmm.portal.ro_sequences.starting_number"),
        field: "startingNumber",
        cellClass: "editable-cell",
        minWidth: 120,
        maxWidth: 200,
        sortingOrder: ["asc", "desc"],
        filter: "agSetColumnFilter",
        filterParams: {
          buttons: ["clear"]
        }
      }
    ];
    return baseCols;
  }
  setFieldValidation(operation, field, errorKey) {
    const { localeStrings } = this.context;
    if (!operation.errors) {
      operation.errors = {};
    }
    operation.errors[field] = localeStrings[errorKey];
    this.refreshCell(operation, field);
    this.updateStatusBox(operation.errors[field], "error", false, true);
  }
  refreshCell(record, field) {
    const rowNode =
      this.gridApi && this.gridApi.getRowNode(record.dealerPayTypeId);
    if (rowNode) {
      const params = {
        // don't do force since cell would be flashed as well
        // force: true,
        columns: [field],
        rowNodes: [rowNode]
      };
      this.gridApi.refreshCells(params);
    }
  }
  clearFieldValidation(params) {
    const { field } = params.colDef;
    const operation = params.data;
    if (operation && operation.errors && operation.errors[field]) {
      operation.errors[field] = "";
    }
  }
  validateField(params) {
    const { colDef, data, newValue } = params;
    const field = colDef ? colDef.field : null;
    let errorKey = null;
    if (data && field) {
      switch (field) {
        case "payCode":
        case "description":
          if (
            doesEmpty(newValue) ||
            formvalidator.validateAlphaNumPunctuation(newValue)
          ) {
            errorKey = "xmm.portal.errors.invalid_field";
          }
          break;
        default:
          break;
      }
    }
    if (errorKey) {
      this.setFieldValidation(data, field, errorKey);
    }
    return !errorKey;
  }

  handleRowSelected = () => {};

  /* This selection handler returns selected records from grid */
  handleSelectionChanged = () => {
    if (this.gridApi) {
      const selectedRows = this.gridApi.getSelectedRows();
      this.setState({ selectionlist: selectedRows });
    }
  };

  updateStatusBox(msg, type, close, errorInTooltip) {
    console.log("status", msg, type, close);
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
    sleep(0).then(() => {
      this.setState({
        statusMsg: msg,
        autoClose: close,
        statusType: type,
        errorInTooltip
      });
    });
  }
  openManageSequenceFromEditor = event => {
    const { data } = event.detail;
    this.setState({ selectedSequence: data, showManageModal: true}, () => {
      // this.manageSequenceModalRef.current.setSelectedItem(data);
    });
  };
  closeManageSequenceModal = () => {
    this.manageSequenceModalRef.current.state.dirty = false;
    this.manageSequenceModalRef.current.state.valid = false;
    this.loadGridData();
    this.loadSequenceNumbers();
    this.setState({ showManageModal: false, showDiscardEditPopup: false});
  };
  setSelectedSequence(name) {
    this.setState({ selectedSequenceName: name});
  }
  findSequenceByName(array, name) {
    for(let i = 0; i < array.length; i++) {
        if(array[i].sequenceName === name) {
            return array[i];
        }
    }
    return 'Object not found';
  }
  renderManageSequenceModal() {
    const { selectedSequence, showManageModal, sequenceList } = this.state;
    const groupChild = <div />;
    const widget = (
      <ManageSequenceModal
        ref={this.manageSequenceModalRef}
        show={showManageModal}
        title={xlate("xmm.portal.ro_sequences.manage_sequences_numbers")}
        selectedSequence={selectedSequence}
        closeModal={event => {
          this.closeManageSequenceModal();
        }}
        loadSequenceNumbers={event => {
          this.loadSequenceNumbers();
        }}
        setSelectedSequence={name => {
          this.setSelectedSequence(name);
        }}
        showDiscardEditPopup={event => {
          this.showDiscardEditPopup();
        }}
        sequenceList={sequenceList}
      >
        {groupChild}
      </ManageSequenceModal>
    );

    return widget;
  }
  handleDeleteSequence = () => {
    const { selectedItem, sequenceList } = this.state;
    const { dealerCode, dealerSequenceNumId } = selectedItem;
    const restUrl = `/ops/proxyapi/oeproxy/rest/internal/dealerSvcTypeSeq/deleteDlrSvcTypeSeqNum/dealerCode/${dealerCode}/dealerSequenceNumId/${dealerSequenceNumId}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };
    makeSecureRestApi(
      {
        url: restUrl,
        method: "delete",
        headers
      },
      response => {
        if (response) {
          this.loadSequenceNumbers();
          this.setState({ selectedSequenceName: "Default", showDeleteSequenceModal: false });
          this.loadGridData();
        }
      },
      error => {
        const msg = error["message"] ? error.message : this.deletingErrorMsg;
        this.updateStatusBox(msg, "error", false, true);
      }
    );
  };
  openDeleteSequenceModal = event => {
    const { selectedItem } = event.detail;
    this.setState({ selectedItem, showDeleteSequenceModal: true });
  };
  closeDeleteSequenceModal = () => {
    this.setState({
      sequenceIdToDelete: null,
      showDeleteSequenceModal: false
    });
  };
  showDiscardEditPopup = () => {
    this.setState({showDiscardEditPopup: true});
  }
  closeDiscardEditPopup = () => {
    this.setState({showDiscardEditPopup: false});
  }
  render() {
    const deleteConfirmationDialog = (
      <Confirmation
        htmlId="deleteSequence"
        message={this.deleteSequenceConfirmationMsg}
        proceedButtonStyle="danger"
        show={this.state.showDeleteSequenceModal}
        actionFunction={this.handleDeleteSequence}
        closeDialog={this.closeDeleteSequenceModal}
      />
    );
    const discardEditPopup = (
      <Confirmation
        htmlId="discardSequence"
        message={xlate("xmm.portal.speed_bump.message")}
        show={this.state.showDiscardEditPopup}
        proceedButtonStyle="danger"
        actionFunction={this.closeManageSequenceModal}
        closeDialog={this.closeDiscardEditPopup}
      />
    );
    const gridWidget = (
      <div id="grid-wrapper">
        <div id="mainGrid" className="ag-grid-container ag-theme-balham ">
          <AgGridReact
            localeText={this.state.localeText}
            columnDefs={this.state.columnDefs}
            defaultColDef={this.state.defaultColDef}
            suppressRowClickSelection={true}
            suppressMenuHide={false}
            suppressContextMenu={true}
            rowData={this.state.serviceTypes}
            rowSelection={this.state.rowSelection}
            rowDeselection={true}
            singleClickEdit={true}
            stopEditingWhenCellsLoseFocus={true}
            animateRows={true}
            onGridReady={this.onGridReady}
            onGridSizeChanged={this.handleGridSizeChanged}
            loadingOverlayComponent={this.state.loadingOverlayComponent}
            loadingOverlayComponentParams={
              this.state.loadingOverlayComponentParams
            }
            noRowsOverlayComponent={this.state.noRowsOverlayComponent}
            noRowsOverlayComponentParams={
              this.state.noRowsOverlayComponentParams
            }
            statusBar={this.state.statusBar}
            components={this.state.components}
            onCellClicked={this.onCellClickedEvent}
            onCellValueChanged={this.onCellValueChanged}
            onColumnResized={this.handleColumnResized}
            getRowNodeId={this.getRowNodeId}
            onRowSelected={this.handleRowSelected}
            onSelectionChanged={this.handleSelectionChanged}
            sideBar={this.state.sideBar}
            columnTypes={this.state.columnTypes}
            multiSortKey={this.state.multiSortKey}
            enableRangeSelection={false}
            enableCellTextSelection={true}
            enableBrowserTooltips={true}
            groupDisplayType={this.state.groupDisplayType}
            groupDefaultExpanded={this.state.groupDefaultExpanded}
            groupRowRendererParams={this.state.groupRowRendererParams}
            onRowGroupOpened={this.onRowGroupOpened}
            onFirstDataRendered={this.onFirstDataRendered}
          />
        </div>
      </div>
    );
    const header = (
      <React.Fragment>
        <div className="content-header">
          <h3 className="xmm-main-title">{this.pageTitle}</h3>
        </div>
      </React.Fragment>
    );
    const manageSequenceModal = this.renderManageSequenceModal();
    return (
      <div className="xmm-center-container">
        {header}
        {gridWidget}
        {manageSequenceModal}
        {deleteConfirmationDialog}
        {discardEditPopup}
      </div>
    );
  }
}
function sequenceNameRender(params) {
  if (params.data && params.data.isDefault) {
    return xlate("xmm.portal.pay_types.default");
  }
  return params.data.sequenceName;
}
function serviceTypeRenderer(params) {
  if (params.data) {
    return (
      params.data.serviceTypeCode.slice(0, 2) + " - " + params.data.serviceType
    );
  }
  return "";
}
export default ROSequencesPage;

ROSequencesPage.propTypes = {};
