/* eslint-disable no-console */
import React, { Component } from "react";
import { PropTypes } from "prop-types";
import ModalDialog from "@cx/ui/ModalDialog";
import ButtonToolbar from "react-bootstrap/lib/ButtonToolbar";
import Button from "@cx/ui/Button";
import Grid from "@cx/ui/Grid";
import Row from "@cx/ui/Row";
import Col from "@cx/ui/Col";
import Alert from "@cx/ui/Alert";
import SelectInput from "@cx/ui/SelectInput";
import ToggleSwitch from "@cx/ui/ToggleSwitch";
import LoadingIndicator from "@cx/ui/LoadingIndicator";
import { toast } from "@cx/ui/Toast";
import { makeSecureRestApi } from "../../../../../api/xmmAxios";
import {
  isArrayExist,
  findRecord
} from "../../../../../commonUtil/utils/object";
import { isEmptyString } from "../../../../../commonUtil/utils/string";
import { formatDateTimezone } from "../../../../../commonUtil/utils/date";
import { AppContext } from "../../../../../components/app-context";
import ManageDealerOpcodes from "./ManageDealerOpcodes";
import StatusBox from "../../../../../commonUtil/components/templates/StatusBox";
import * as gtmEvents from "../../../../utils/gtag-eventlist";
import { locale, xlate } from "../../../../../commonUtil/i18n/locales";
import { getEnhancedDealerCode } from "../../../../../commonUtil/utils/dealer";
class ValidateOpcodeModal extends Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);

    // console.log("validate modal> app context", context);
    this.validateMappingByMake = this.validateMappingByMake.bind(this);
    this.cancelOpCodeValidation = this.cancelOpCodeValidation.bind(this);
    this.updateStatusBox = this.updateStatusBox.bind(this);

    this.initializeLocaleValues();
    const { dealer, dealerCatalogs } = context;
    this.state = {
      // Used as cache - store dealer-level status object (ALL Makes case)
      dmsPulled: false,
      dealerValidationState: {},
      allMakesValidationState: this.props.allMakesValidationState,
      showMask: true,
      hideWidget: true,
      okText: this.props.okText || "Ok",
      cancelText: this.props.cancelText || "Cancel",
      supportedMakes: dealerCatalogs,
      // This holds selected Make record
      selectedMake: {
        priorityOpcodeStrings: "",
        make: "all",
        variant: "",
        dealerCode: dealer.dealerCode
      },
      // stateProps used to disable fields
      disableValidate: true,
      disableTimeFrame: true,
      disableTogglePull: true,
      doesPullChecked: false,
      lastPullMsg: "",
      statusMsg: "",
      statusType: "info",
      // This object will be updated with single make validation status object
      validateStatusObj: {
        allowPull: 0,
        dmsType: "",
        lastPull: "",
        lastValidation: "",
        opcodeScoringEnabled: null,
        priorityOpcodeStrings: "",
        supportsPull: 0,
        validationInProgress: 0
      },
      dirty: false,
      // stateProps binded to cx fields
      make: "",
      roLookupMonths: "24",
      opcodeScoringEnabled: null, // set dealer level value here
      allowPull: false // binded with toggle change event
    };
  }
  /**
   * Add event listener
   */
  componentDidMount() {
    // console.log("All makes status{}", this.props.allMakesValidationState);
    this.preSelectMake();
  }
  /* This called when component gets updates
   This method is not called for the initial render.
  */
  componentDidUpdate() {
    // console.log("Cmp Didupdate called...");
  }
  initializeLocaleValues() {
    this.savingMsg = xlate("xmm.portal.common.saving");
    this.savedMsg = xlate("xmm.portal.common.saved");
  }

  hide = () => {
    this.props.closeModal();
  };
  customClose = () => {
    this.resetState();
    this.props.closeModal();
  };
  manageMakes = () => {
    // call this to hide and swap layouts
    this.setState(prevState => ({
      hideWidget: !prevState.hideWidget
    }));
    gtmEvents.gtmTrackEvent("xmm.opcodevalidation.manage_makes_click");
  };
  backToMain = () => {
    // callback to load All makes status loadAllMakesValidationStatus()
    this.setState(
      prevState => ({
        hideWidget: !prevState.hideWidget
      }),
      () => {
        this.loadAllMakesValidationStatus();
      }
    );
  };
  resetState() {
    const { dealerCode } = this.context.dealer;
    this.setState({
      showMask: true,
      make: "",
      roLookupMonths: "24",
      allowPull: false,
      selectedMake: {
        priorityOpcodeStrings: "",
        make: "all",
        variant: "",
        dealerCode
      },
      // state for disable fields
      disableValidate: true,
      disableTimeFrame: true,
      disableTogglePull: true,
      doesPullChecked: false,
      lastPullMsg: "",
      statusMsg: "",
      statusType: "info",
      dirty: false
    });
  }
  /* bind change events for each filter */
  onChange = cxEvent => {
    const { name, value } = cxEvent.target;
    this.setState({ [name]: value, dirty: true });
  };
  onMakeChange = event => {
    const { name, value } = event.target;
    if (value && value !== "all") {
      this.setState({
        [name]: value,
        showMask: true, // false - to show loading icon
        dirty: true
      });
      this.findMake(value);
    } else if (value === "all") {
      const { supportedMakes } = this.state;
      this.setState(
        {
          selectedMake: supportedMakes[0],
          make: value,
          showMask: true
        },
        () => {
          /* when ALL makes option selected, always set DMS Pull flag from rest API and disable validate button based on response */
          this.getDealerDmsInfo();
        }
      );
    }
  };

  onToggleClick = cxEvent => {
    const checked = cxEvent.target.value === "checked";
    this.setState({
      dmsPulled: checked,
      dirty: true,
      allowPull: checked ? 1 : 0
    });
  };
  /* This method will pre-select make in case we have one make; else default to "All Makes"  */
  preSelectMake() {
    const { dealerCatalogs } = this.context;
    if (dealerCatalogs && dealerCatalogs.length === 1) {
      const selectedMake = dealerCatalogs[0];
      const make = selectedMake && selectedMake.value;
      // When dealer has single Make, call rest API to get opcode status
      this.setState(
        {
          selectedMake,
          make,
          showMask: false // false - to show loading
        },
        () => {
          // console.log("preSelected make{} ", selectedMake);
          if (make) {
            this.getValidationStatusByMake(selectedMake);
          }
        }
      );
    } else if (dealerCatalogs.length > 1) {
      this.loadMakesFilter();
    }
  }
  loadMakesFilter() {
    const { dealerCatalogs } = this.context;
    const supportedMakes = this.generateMakes(dealerCatalogs);
    const makeVal = supportedMakes[0] && supportedMakes[0].value;
    this.setState(
      {
        selectedMake: supportedMakes[0],
        make: makeVal,
        supportedMakes,
        showMask: true
      },
      () => {
        if (makeVal.toLowerCase() === "all") {
          // when Validate Modal is opened, get dealer-level data
          this.getDealerDmsInfo();
        }
      }
    );
  }

  /* This method to find make from All makes validate status object  */
  findMake(makeName) {
    const { dealerValidationState } = this.state;
    const { dealerCatalogs } = this.context;
    const rawStatus = this.state.allMakesValidationState;
    const property = "make";
    const selectedMake = findRecord(dealerCatalogs, property, makeName);
    if (rawStatus.length > 0) {
      const found = rawStatus.filter(function (makeobj) {
        return makeobj.make === makeName;
      });
      if (found.length === 1) {
        const cloneObj = Object.assign({}, found[0]);
        if (Object.keys(dealerValidationState).length > 0) {
          cloneObj.opcodeScoringEnabled =
            dealerValidationState.opcodeScoringEnabled;
          cloneObj.dmsType = dealerValidationState.dmsType;
        }
        console.log("FindMake status{}", cloneObj);
        this.setState(
          {
            selectedMake,
            makeName,
            showMask: true // true - to hide loading
          },
          () => {
            if (selectedMake.make) {
              this.callbackValidateStatus(cloneObj);
            }
          }
        );
      }
    }
  }
  /* Method returns Makes[] with "All Makes" option */
  generateMakes = makeslist => {
    const { dealerCode } = this.context.dealer;
    let records = [];
    if (makeslist.length > 0) {
      records = makeslist.slice(0);
      records.splice(0, 0, {
        label: this.context.localeStrings["xmm.portal.common.all_makes"],
        value: "all",
        make: "all",
        dealerCode,
        priorityOpcodeStrings: "",
        variant: "all"
      });
    }
    return records;
  };
  // push event to GTM
  trackValidateEvent = make => {
    const tagObj = {
      eventResult:
        make !== "all"
          ? "Validate Opcode Mapping for Make " + make
          : "Validate Opcode Mapping for All Makes",
      eventFeature: "Operations> Opcode validation Page",
      eventFeatures:
        "opcode validation page> Validate Opcode Mapping> Validate",
      trackPageUrl:
        "operations/opcode-validation/validate-opcode-mapping/validate/" + make
    };
    gtmEvents.gtmTrackEventWithParams(
      "xmm.opcodevalidation.validate_opcode_save_click",
      tagObj
    );
  };

  /* Validate action handler works for All Makes & Single make case */
  validateMappingByMake = event => {
    const { allowPull } = this.state;
    const { dealerCode, make, variant } = this.state.selectedMake;
    let payload = {};
    let restUrl = "";
    let trackMake = "all";
    // console.log(
    //   "validate action clicked",
    //   this.state.make,
    //   this.state.selectedMake
    // );
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };
    if (this.state.make && this.state.make !== "all") {
      trackMake = make;
      payload = {
        email: "",
        roLookupMonths: this.state.roLookupMonths,
        isDoPull: allowPull === 1 || allowPull === true ? true : false,
        priorityOpcodeStrings:
          this.state.validateStatusObj.priorityOpcodeStrings
      };
      restUrl =
        "ops/proxyapi/internaloeproxy/rest/internal/opcode/" +
        dealerCode +
        "/make/" +
        make +
        "/variant/" +
        variant +
        "/generateScores";
    } else {
      trackMake = "all";
      payload = {
        roLookupMonths: this.state.roLookupMonths,
        isDoPull: allowPull === 1 || allowPull === true ? true : false
      };
      restUrl =
        "ops/proxyapi/internaloeproxy/rest/internal/opcode/" +
        this.context.dealer.dealerCode +
        "/generateAllScores";
    }
    makeSecureRestApi(
      {
        url: restUrl,
        method: "get",
        data: {},
        params: payload,
        headers
      },
      data => {
        if (data && typeof data === "object") {
          if (data.statusCode === 0) {
            this.setState({
              statusMsg: "",
              disableValidate: true,
              showMask: true
            });
            const started = true;
            this.props.okAction(event, started, this.state.make); // to close window
            this.context.setCancelOpcodeValidationDate(null);
          }
        }
      },
      error => {
        toast.error(error.message);
      }
    );
    this.trackValidateEvent(trackMake);
  };
  /* Deprecated - cancel opcode validation process for selected Make */
  cancelOpCodeValidation = () => {
    const { dealerCode, make, variant } = this.state.selectedMake;
    const payload = {
      make,
      variant,
      dealerCode
    };
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };
    const restEndPoint = "/ops/proxyapi/ddsproxy/rest/proc/cancelOpcodeScoring";
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "get",
        data: {},
        params: payload,
        headers
      },
      data => {
        if (data) {
          if (isArrayExist(data) && data.length > 0) {
            const cancelObj = data[0];
            if (cancelObj && cancelObj.isError === 0) {
              this.resetState();
            } else {
              toast.error("Error while canceling opcode validation");
            }
          }
        }
      },
      error => {
        toast.error(error.message);
      }
    );
  };
  /* Method returns dealer-level DMS details to map with Validation fields (ALL Makes) case  */
  getDealerDmsInfo() {
    // staged dealer need to use live dealercode
    const { dealerCode, isStaging } = getEnhancedDealerCode(
      this.context.dealer.dealerCode
    );
    console.log("getDealerDmsInfo", isStaging, dealerCode);
    const payload = {
      dealerCode
    };
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };
    const restEndPoint = "/ops/proxyapi/ddsproxy/rest/proc/getDealerDmsInfo";
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "get",
        data: {},
        params: payload,
        headers
      },
      data => {
        if (data) {
          let statusObj = {
            allowPull: 0,
            dmsType: "",
            lastPull: "",
            lastValidation: "",
            opcodeScoringEnabled: 1,
            priorityOpcodeStrings: "",
            supportsPull: 1,
            validationInProgress: 0
          };
          // read obj {allowPull, opcodeScoringEnabled, lastPull}
          if (isArrayExist(data) && data.length > 0) {
            statusObj = data[0];
            statusObj.priorityOpcodeStrings = "-";
            statusObj.lastValidation = statusObj.lastPull;
            statusObj.validationInProgress = 0;
          }
          this.setState(
            {
              dealerValidationState: statusObj,
              validateStatusObj: statusObj,
              allowPull: statusObj.allowPull,
              opcodeScoringEnabled: statusObj.opcodeScoringEnabled,
              showMask: true
            },
            () => {
              this.checkOpcodeValidationStatus(statusObj); // this call binds state to UI fields
            }
          );
          this.setState({ dmsPulled: statusObj.allowPull === 1 });
          // This rest API returns latest validation status for ALL Makes selection
          this.loadAllMakesValidationStatus();
        }
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "There was an error to fetch data. Please try again later.";
        toast.error(msg, {
          closeOnClick: true
        });
      }
    );
  }
  // Read Dealer-level status data from cache for "All Makes" case
  getDealerDmsCache() {
    const { dealerValidationState } = this.state;
    if (Object.keys(dealerValidationState).length > 0) {
      this.callbackValidateStatus(dealerValidationState);
    }
  }
  // callback - Update state{} with selected make status object
  callbackValidateStatus(statusObj) {
    console.log("callbackValidateStatus()", statusObj);
    this.checkOpcodeValidationStatus(statusObj); // bind status to ui fields
    this.setState({
      validateStatusObj: statusObj,
      allowPull: statusObj.allowPull,
      opcodeScoringEnabled: statusObj.opcodeScoringEnabled,
      showMask: true
    });
    // null check in case rest service is running and user cancel the modal
    this.setState({ dmsPulled: statusObj.allowPull === 1 });
  }
  /* Method returns opcode Validation status{} for single Make  */
  getValidationStatusByMake(selectedMake) {
    const payload = {
      make: selectedMake.value,
      variant: selectedMake.variant,
      dealerCode: selectedMake.dealerCode
    };
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      "dds-json-conversion-style": "camel-case"
    };
    const restEndPoint =
      "/ops/proxyapi/ddsproxy/rest/proc/getOpcodeValidationStatus";
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "get",
        data: {},
        params: payload,
        headers
      },
      data => {
        if (data) {
          let datalist = [];
          let statusObj = {
            allowPull: 0,
            dmsType: "",
            lastPull: "",
            lastValidation: "",
            opcodeScoringEnabled: null,
            priorityOpcodeStrings: "",
            supportsPull: 0,
            validationInProgress: 0
          };
          if (!isArrayExist(data) && typeof data === "object") {
            datalist.push(data);
          } else if (isArrayExist(data) && data.length > 0) {
            datalist = data;
          }
          if (isArrayExist(datalist) && datalist.length === 0) {
            // set default statusObj
          } else {
            statusObj = datalist[0];
          }
          console.log(
            "Single Make validation status for",
            selectedMake.value,
            statusObj
          );
          this.callbackValidateStatus(statusObj);
        }
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "There was an error to fetch data. Please try again later.";
        toast.error(msg, {
          closeOnClick: true
        });
      }
    );
  }
  /* This method will disable validation fields and format values based on single make validation status */
  checkOpcodeValidationStatus(statusObj) {
    let markDisabled = false;
    let disableTimeFrame = true;
    let disableTogglePull = true;
    let doesPullChecked = false;
    let lastPullMsg = "";
    let disableValidate = true;
    const status = {
      statusType: "info",
      statusMsg: ""
    };
    if (Object.keys(statusObj).length > 0) {
      // disable validate button when validationInProgress = 1
      if (statusObj.hasOwnProperty("validationInProgress")) {
        markDisabled = statusObj.validationInProgress === 1 ? true : false;
        // Note: No need to show banner for each make selection in modal
        // status = this.updateBanner(statusObj);
      }
      if (markDisabled) {
        disableTimeFrame = true;
      } else {
        // if 0 - no scoring;  1 - allow scoring for dealers
        if (
          statusObj.opcodeScoringEnabled !== null &&
          statusObj.opcodeScoringEnabled === 1
        ) {
          disableTimeFrame = false;
          disableValidate = false;
        }
      }

      // when "allowPull" = 1; Show DMS Pull container & by default select request checkbox
      if (statusObj.hasOwnProperty("allowPull")) {
        if (statusObj.allowPull === 1) {
          // show toggle with checked
          disableTogglePull = false;
          doesPullChecked = true;
        } else {
          // show toggle with unchecked and disable
          disableTogglePull = true;
          doesPullChecked = false;
        }
      }
      // display DMS Pull message only when Last Pull date exists
      console.log("checkOpcodeValidationStatus()", statusObj);
      if (
        statusObj.hasOwnProperty("lastPull") &&
        !isEmptyString(statusObj.lastPull)
      ) {
        let isPulledToday = false;
        const { locale } = this.context;
        // if lastPull is date string, Format as June 15, 2007 at 2:05am PDT
        lastPullMsg = formatDateTimezone(statusObj.lastPull, true, locale);
        isPulledToday = this.check24hoursLastPullDate(statusObj);
        doesPullChecked = !isPulledToday;
      } else {
        lastPullMsg = xlate("xmm.portal.msg.opcodes_not_pulled");
      }
      disableValidate = this.disableValidateFeature(statusObj);
      // update state with validation fields
      this.setState({
        disableValidate,
        disableTimeFrame,
        disableTogglePull,
        doesPullChecked,
        lastPullMsg,
        statusMsg: status.statusMsg,
        statusType: status.statusType
      });
    } // if
  }
  /* Deprecated -
    Method to display banner text based on status object
    Click "Validate Op Code Mapping" to check your current setup or to setup for the first time.
    Op Code Validation is in Progress. Please check back later for results. Cancel
    Displaying results from validation completed on August 29, 2019 at 3:05pm CDT. Please fix any Invalid opcodes below.
  */
  updateBanner(statusObj) {
    const { locale } = this.context;
    let status = {};
    let lastValidationDate = "";
    let bannerText = "";
    let bannerType = "info";
    if (statusObj.hasOwnProperty("validationInProgress")) {
      if (statusObj.validationInProgress === 1) {
        bannerType = "warning";
        bannerText =
          "Op Code Validation is in Progress. Please check back later for results.";
        // when "complete" status, check if last validation date exists
      } else if (
        statusObj.validationInProgress === 0 &&
        !isEmptyString(statusObj.lastValidation)
      ) {
        // if lastValidation is date string, use Ext.util.Format to format string as date i,e: June 15, 2007 at 2:05am PDT
        lastValidationDate = formatDateTimezone(
          statusObj.lastValidation,
          true,
          locale
        );
        bannerType = "success";
        bannerText =
          "Displaying results from validation completed on " +
          lastValidationDate +
          ". Please fix any Invalid opcodes below.";
        // when validation not started yet, check if last validation date is null
      } else if (
        statusObj.validationInProgress === 0 &&
        isEmptyString(statusObj.lastValidation)
      ) {
        bannerType = "info";
        bannerText =
          "Click 'Validate' to check your current setup or to setup for the first time";
      } else {
        bannerType = "info";
        bannerText = "";
      }
    } else {
      bannerText = "";
    }

    status = {
      statusType: bannerType,
      statusMsg: bannerText
    };
    return status;
  }
  /* This method used to disable "Request DMS Pull" checkbox when last pull date is within 24 hours */
  check24hoursLastPullDate(statusObj) {
    const currentDateobj = new Date();
    let dt;
    if (statusObj.lastPull) {
      dt = statusObj.lastPull;
      return currentDateobj.getTime() - new Date(dt).getTime() < 86400000;
    }
    return false;
  }

  /*
   1. First check if opcode scoring allowed for ADP dealers and enable validate features
   2. Last check if validation "inprogress" status, disable "validate opcode mapping" button
   */
  disableValidateFeature(statusObj) {
    let markDisabled = false;
    let disableValidate = true;

    if (statusObj.hasOwnProperty("validationInProgress")) {
      markDisabled = statusObj.validationInProgress === 1 ? true : false;
    }

    if (markDisabled) {
      disableValidate = true;
    } else {
      // if 0 - no scoring;  1 - allow scoring for ADP dealers
      disableValidate =
        statusObj.hasOwnProperty("opcodeScoringEnabled") &&
        statusObj.opcodeScoringEnabled === 1
          ? false
          : true;
    }
    return disableValidate;
  }
  /* This method used to get All opcode validation status and update local state{} */
  loadAllMakesValidationStatus() {
    const { dealerCode } = this.context.dealer;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };
    makeSecureRestApi(
      {
        url: "ops/proxyapi/ddsproxy/rest/proc/getAllOpcodeValidationStatus",
        method: "get",
        data: {},
        params: { dealerCode },
        headers
      },
      data => {
        if (data) {
          let datalist = [];
          if (!isArrayExist(data) && typeof data === "object") {
            datalist.push(data);
          } else if (isArrayExist(data) && data.length > 0) {
            datalist = data;
          }
          this.setState(
            {
              allMakesValidationState: datalist
            },
            () => {
              const { make } = this.state;
              // load status for pre-selected make from cache
              console.log("BackAction click - Make", make);
              if (make.toLowerCase() !== "all") {
                // Update parent state with latest All makes status
                this.props.updateAllMakeStatus(datalist);
                this.findMake(make);
              } else {
                // All Makes case - check for {allowPull, lastPull, validationInProgress} and  disable "Validate" button
                this.transformAllMakesStatus(datalist);
              }
            }
          );
        }
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "There was an error while fetching data. Please try again later.";
        toast.error(msg);
      }
    );
  }
  transformAllMakesStatus(datalist) {
    if (datalist && datalist.length > 0) {
      const found = datalist.filter(function (makeobj) {
        return makeobj.make === "ALL";
      });
      if (found.length === 1) {
        const recordOfAllMakes = found[0];
        const { isStaging } = this.context;
        const { dealerValidationState } = this.state;
        let cloneObj = {};
        // if dealer is staged; set DealerDMS data of live dealer
        if (isStaging) {
          if (Object.keys(dealerValidationState).length > 0) {
            cloneObj = Object.assign({}, dealerValidationState);
          }
          // opcode status data of staged dealer
          // cloneObj.lastValidation = recordOfAllMakes.lastValidation;
          // cloneObj.lastPull = recordOfAllMakes.lastPull;
          cloneObj.validationInProgress = recordOfAllMakes.validationInProgress;
        } else {
          // live dealer
          cloneObj = Object.assign({}, recordOfAllMakes);
        }
        console.log("transformAllMakesStatus() for ALL Makes", cloneObj);
        this.callbackValidateStatus(cloneObj);
      }
    }
  }
  updateStatusBox(msg, type, close, errorInTooltip) {
    console.log("status", msg, type, close);
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
    sleep(0).then(() => {
      this.setState({
        statusMsg: msg,
        autoClose: close,
        statusType: type,
        errorInTooltip
      });
    });
  }
  render() {
    let loadmask = null;
    let gridwidget = null;
    // const { localeStrings } = this.context;
    const widgetHide = this.state.hideWidget ? "hide" : "";
    const formHide = this.state.hideWidget ? "" : "hide";
    const {
      okText,
      cancelText,
      selectedMake,
      validateStatusObj,
      disableValidate,
      disableTimeFrame,
      disableTogglePull,
      lastPullMsg,
      statusType,
      statusMsg,
      showMask,
      dmsPulled
    } = this.state;
    let dmsName = this.context.dealer.dmsType;
    if (validateStatusObj.dmsType) {
      dmsName = validateStatusObj.dmsType;
    }
    let opcodeString = selectedMake.priorityOpcodeStrings || "";
    if (validateStatusObj.priorityOpcodeStrings) {
      opcodeString = validateStatusObj.priorityOpcodeStrings;
    }
    if (!this.state.hideWidget) {
      const statusBox = this.state.statusMsg ? (
        <StatusBox
          htmlId="statusBox"
          type={this.state.statusType}
          autoClose={this.state.autoClose}
          linkHtml={null}
          message={this.state.statusMsg}
          autoCloseTime={1500}
          errorInTooltip={this.state.errorInTooltip}
        />
      ) : (
        ""
      );
      gridwidget = (
        <div className={widgetHide}>
          <div style={{ width: "100%" }}>
            <Button
              className="float-left"
              htmlId="popupManagebtn"
              onClick={this.backToMain}
              text="BackToMain"
              buttonStyle="secondary"
            >
              {"< "} {xlate("xmm.portal.common.back_lbl")}
            </Button>
            <div className="float-right">{statusBox}</div>
          </div>

          <ManageDealerOpcodes
            allMakesValidationState={this.state.allMakesValidationState}
            updateStatusBox={this.updateStatusBox}
          />
        </div>
      );
    }
    const cancelLink = (
      <Button
        htmlId="cancelOpCodeValidation"
        buttonStyle="link"
        onClick={this.cancelOpCodeValidation}
      >
        {xlate("xmm.portal.common.cancel_button")}
      </Button>
    );
    /* Hide banner for any make selection */
    let banner = null;
    if (statusMsg) {
      banner = (
        <Alert
          htmlId="opcodeBanner"
          className="hide"
          type={statusType}
          displayCloseButton={statusType === "success"}
        >
          {statusMsg} {statusType === "warning" ? cancelLink : ""}
        </Alert>
      );
    }
    /* placeholder to show mask */

    if (!showMask) {
      loadmask = (
        <LoadingIndicator
          htmlId="loadmasking"
          size="small"
          floatToSide="left"
        />
      );
    }
    const col1 = locale === "fr_CA" ? 6 : 5;
    const col2 = locale === "fr_CA" ? 6 : 7;
    return (
      <React.Fragment>
        <ModalDialog
          htmlId="ValidateOpcodeModal"
          show={this.props.show}
          backdrop={"static"}
          className="xmm-validate-opcode-modal"
          header={<ModalDialog.Title>{this.props.title}</ModalDialog.Title>}
          onHide={this.customClose}
          footer={false}
        >
          <Grid htmlId="ValidateOpcodeContent" className={formHide}>
            {banner}
            {loadmask}
            <div className="xmm-modal-help-text">
              {xlate("xmm.portal.opcode_validation.popup_header_msg")}
            </div>
            <Row>
              <Col xs={col1} md={col1}>
                <span className="float-right">
                  {xlate("xmm.portal.common.make_lbl")}
                </span>
              </Col>
              <Col xs={col2} md={col2}>
                <SelectInput
                  htmlId="makeSelect"
                  label=""
                  name="make"
                  placeholder={xlate("xmm.portal.common.select_a_make_lbl")}
                  onChange={this.onMakeChange}
                  displayDeselectOption={false}
                  displayLabel={false}
                  options={this.state.supportedMakes}
                  value={this.state.make}
                  maxHeight={200}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={col1} md={col1}>
                <span className="float-right">
                  {xlate("xmm.portal.opcode_validation.field_time_frame_lbl")}
                </span>
              </Col>
              <Col xs={col2} md={col2}>
                <SelectInput
                  htmlId="timeframeSelect"
                  label=""
                  name="roLookupMonths"
                  displayPlaceholder={false}
                  displayDeselectOption={false}
                  placeholder="Select"
                  displayLabel={false}
                  disabled={disableTimeFrame}
                  onChange={this.onChange}
                  options={[
                    {
                      value: "6",
                      label: xlate("xmm.portal.common.6_months_lbl")
                    },
                    {
                      value: "12",
                      label: xlate("xmm.portal.common.1_year_lbl")
                    },
                    {
                      value: "24",
                      label: xlate("xmm.portal.common.2_years_lbl")
                    },
                    {
                      value: "36",
                      label: xlate("xmm.portal.common.3_years_lbl")
                    }
                  ]}
                  value={this.state.roLookupMonths}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={col1} md={col1}>
                <span className="float-right">
                  {xlate("xmm.portal.opcode_validation.field_dms_lbl")}
                </span>
              </Col>
              <Col xs={col2} md={col2}>
                <div className="xmm-form-label">{dmsName || ""}</div>
              </Col>
            </Row>
            <Row>
              <Col xs={col1} md={col1}>
                <span className="float-right">
                  {xlate(
                    "xmm.portal.opcode_validation.field_priority_opcode_lbl"
                  )}
                </span>
              </Col>
              <Col xs={col2} md={col2}>
                <div className="xmm-form-label xmm-wrapped-text">
                  {opcodeString || "-"}
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={col1} md={col1}>
                <span className="float-right">
                  {xlate("xmm.portal.opcode_validation.field_dms_pull_lbl")}
                </span>
              </Col>
              <Col xs={col2} md={col2}>
                <ToggleSwitch
                  htmlId="togglePullOpCodes"
                  checked={dmsPulled}
                  name="allowPull"
                  onClick={this.onToggleClick}
                  disabled={disableTogglePull}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={col1} md={col1}>
                <span className="float-right">
                  {xlate("xmm.portal.opcode_validation.last_occurred_lbl")}
                </span>
              </Col>
              <Col xs={col2} md={col2}>
                <div className="xmm-form-label">{lastPullMsg || " "}</div>
              </Col>
            </Row>
            <div className="xmm-modal-help-text">
              {xlate("xmm.portal.opcode_validation.popup_footer_msg")}
            </div>
            <ButtonToolbar>
              <Button
                htmlId="popupManagebtn"
                onClick={this.manageMakes}
                text={xlate("xmm.portal.opcode_validation.manage_makes_lbl")}
                buttonStyle="secondary"
              >
                {xlate("xmm.portal.opcode_validation.manage_makes_lbl")}
              </Button>
              <Button
                htmlId="popupCancelbtn"
                onClick={this.customClose}
                text="cancel"
                className="float-right"
                buttonStyle="secondary"
              >
                {cancelText}
              </Button>
              <Button
                htmlId="popupDiscardbtn"
                onClick={this.validateMappingByMake}
                disabled={disableValidate}
                buttonStyle="primary"
                className="float-right"
                text={xlate("xmm.portal.opcode_validation.validate_lbl")}
              >
                {okText}
              </Button>
            </ButtonToolbar>

            {this.props.children}
          </Grid>
          {gridwidget}
        </ModalDialog>
      </React.Fragment>
    );
  }
}
export default ValidateOpcodeModal;

ValidateOpcodeModal.propTypes = {
  show: PropTypes.bool,
  children: PropTypes.object,
  title: PropTypes.string,
  okText: PropTypes.string,
  cancelText: PropTypes.string,
  closeModal: PropTypes.func,
  okAction: PropTypes.func,
  updateAllMakeStatus: PropTypes.func,
  allMakesValidationState: PropTypes.array
};
/* eslint-enable no-console */
