/* eslint-disable no-console */
import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import { AppContext } from "../../../../components/app-context";
import CustomLoadingOverlay from "../../../../commonUtil/components/loadingmask/CustomLoadingOverlay";
import Button from "@cx/ui/Button";
import IconDelete from "@cx/ui/Icons/IconDelete";
import Popover from "@cx/ui/Popover";
import IconInfoOutline from "@cx/ui/Icons/IconInfoOutline";
import GenericSlider from "../../../../commonUtil/components/GenericSlider";
import { doesEmpty } from "../../../../commonUtil/utils/object";
import { applyCustomKeyNavigation } from "../../../../commonUtil/utils/keyNavigation";
import { toEmptyStringIfUndefined } from "../../../../commonUtil/utils/string";
import TextEditor from "../../../../commonUtil/editors/TextEditor";
import {
  makeSecureRestApi,
  showBodyMask,
  hideBodyMask
} from "../../../../api/xmmAxios";
import {
  ServiceTypesRecord,
  // PayTypeGroup,
  DealerTrackDefaultPayTypes
} from "../../../../constants/ModuleConstants";
import AddServiceTypesFormPage from "./content/AddServiceTypesFormPage";
import { toast } from "@cx/ui/Toast";
import Confirmation from "../../../../commonUtil/dialog/Confirmation";
import { loadAgGridLocale } from "../../../../i18n/LocaleSender";
import { xlate } from "../../../../commonUtil/i18n/locales";
import StatusBox from "../../../../commonUtil/components/templates/StatusBox";
import { getCellClassRule } from "../../../../commonUtil/utils/validation";
import * as formvalidator from "../../../formik/formvalidator";

class ServiceTypesPage extends Component {
  static contextType = AppContext;

  constructor(props, context) {
    super(props, context);

    this.payTypeFormRef = React.createRef();

    // Bind grid functions in constructor
    this.getRowNodeId = this.getRowNodeId.bind(this);
    this.onCellClickedEvent = this.onCellClickedEvent.bind(this);
    this.onCellValueChanged = this.onCellValueChanged.bind(this);
    this.handleColumnResized = this.handleColumnResized.bind(this);
    // bind other external actions
    this.addActionSlider = this.addActionSlider.bind(this);
    this.hideSlider = this.hideSlider.bind(this);
    this.closeDeleteModal = this.closeDeleteModal.bind(this);
    this.confirmDeletePayType = this.confirmDeletePayType.bind(this);
    // this.deletePayType = this.deletePayType.bind(this);
    this.renderDeleteButton = this.renderDeleteButton.bind(this);
    this.updateServiceTypesAfterSave =
      this.updateServiceTypesAfterSave.bind(this);
    this.updateStatusBox = this.updateStatusBox.bind(this);
    this.onFirstDataRendered = this.onFirstDataRendered.bind(this);
    this.handleSaveCellEdit = this.handleSaveCellEdit.bind(this);
    this.handleDeleteServiceTypes = this.handleDeleteServiceTypes.bind(this);
    this.handleGridSizeChanged = this.handleGridSizeChanged.bind(this);
    this.getColumnList = this.getColumnList.bind(this);

    const { dealer, localeStrings, enableDMSPlusContent } = context;

    const { commonOpsLaborGridsEnabled, dmsType } = dealer;
    const isDealerTrack =
      dmsType === "DEALERTRACK" || enableDMSPlusContent;

    this.initializeLocaleValues();
    const validationFields = ["description", "payCode"];
    const editableFields = ["description", "payCode"];
    const checkboxFields = ["defaultPayType"];
    this.groupList = [];
    const gridOptions = {
      // other state props
      commonOpsLaborGridsEnabled,
      searchKey: context.payTypesGrid.searchKey,
      payTypesGrid: context.payTypesGrid,
      pageTitle: this.pageTitle,
      editOption: null, // set values as edit, add, delete
      operationName: "",
      rowRecord: ServiceTypesRecord,
      sliderWidth: 500,
      flexWidth: false,
      showSlide: false,
      isDealerTrack,
      // ag-grid props
      serviceTypes: null, // should be null - fix to skip "No records found" msg on grid load.
      selectionlist: [],
      columnDefs: this.getColumnList(localeStrings, commonOpsLaborGridsEnabled),
      defaultColDef: {
        floatingFilter: false, // true - enable column header filters
        sortable: true,
        resizable: true,
        editable: false, // default disable editor
        enableRowGroup: false,
        sortingOrder: ["asc", "desc", null],
        width: 120,
        autoHeight: true,
        filter: "agTextColumnFilter",
        cellClassRules: getCellClassRule(
          validationFields,
          editableFields,
          checkboxFields
        ),
        getQuickFilterText: params => {
          if (!params.column.visible) {
            return null;
          } else {
            return params.value;
          }
        },
        headerComponentParams: {
          template: `
          <div class="ag-cell-label-container" role="presentation">
            <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
            <div ref="eLabel" class="ag-header-cell-label" role="presentation">
              <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
              <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
              <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>
              <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>
              <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>
            </div>
          </div>
          `
        },
        suppressKeyboardEvent: applyCustomKeyNavigation,
        rowGroup: false
      },
      multiSortKey: "ctrl",
      components: {},
      loadingOverlayComponent: CustomLoadingOverlay,
      loadingOverlayComponentParams: {
        loadingMessage: "Loading",
        isLoading: true,
        noRows: false
      },
      noRowsOverlayComponent: CustomLoadingOverlay,
      noRowsOverlayComponentParams: {
        loadingMessage: "No records found.",
        isLoading: false,
        noRows: true
      },
      statusBar: {
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left"
          },
          {
            statusPanel: "agFilteredRowCountComponent"
          },
          {
            statusPanel: "agSelectedRowCountComponent",
            align: "left"
          }
        ]
      },
      // true - use browser default tooltip instead of ag-grid tooltip
      enableBrowserTooltips: true,
      columnTypes: {
        numberColumn: {
          maxWidth: 130,
          minWidth: 130,
          filter: "agNumberColumnFilter",
          filterParams: {
            includeBlanksInEquals: false,
            includeBlanksInLessThan: false,
            includeBlanksInGreaterThan: false,
            buttons: ["clear"]
          }
        },
        nonEditableColumn: { editable: false },
        noFilterColumn: {
          width: 100,
          columnGroupShow: "open",
          filter: false
        }
      },
      rowSelection: "multiple",
      isRowSelectable() {
        return true; // to see checkbox
        // return rowNode.data ? rowNode.data.make !== "ANY" : false;
      },
      localeText: loadAgGridLocale(localeStrings),
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
            toolPanelParams: {
              suppressPivots: true,
              suppressPivotMode: true,
              suppressValues: true,
              suppressRowGroups: true
            }
          }
        ],
        hiddenByDefault: false
      }
    };
    this.state = gridOptions;
  }
  /**
   * Add event listeners
   * when an instance of a component is being created and inserted into the DOM
   */
  componentDidMount() {
    window.addEventListener(
      "saveCellEditEvent",
      this.handleSaveCellEdit,
      false
    );
  }
  /**
   * Remove event listeners
   * when a component is being removed from the DOM
   */
  componentWillUnmount() {
    this.saveGridState();
    window.removeEventListener(
      "saveCellEditEvent",
      this.handleSaveCellEdit,
      false
    );
  }
  initializeLocaleValues() {
    this.option = xlate("xmm.portal.pay_types.option");
    this.addServiceTypesLabel = xlate("xmm.portal.service_types.add");
    this.pageTitle = xlate("xmm.portal.nav.service_types");
    this.deletingMsg = xlate("xmm.portal.common.deleting");
    this.deletedMsg = xlate("xmm.portal.common.deleted");
    this.deletingErrorMsg = xlate("xmm.portal.errors.deleting");
    this.savingMsg = xlate("xmm.portal.common.saving");
    this.savedMsg = xlate("xmm.portal.common.saved");
    this.saveErrorMsg = xlate("xmm.portal.common.cannot_save_changes");
  }
  /* Action to save ag-grid {column, filter, pivot, sort} to local state
   */
  saveGridState() {
    const { searchKey } = this.state;
    if (this.gridApi && this.gridColumnApi) {
      const payTypesGrid = {
        colState: this.gridColumnApi.getColumnState(),
        pivotState: this.gridColumnApi.isPivotMode(),
        filterState: this.gridApi.getFilterModel(),
        searchKey
      };
      this.setState({
        payTypesGrid
      });
      this.context.setPayTypesGridState(payTypesGrid);
    }
  }
  /* This Util called to restore ag-grid controls,filters,sorters from app-context when re-visited page */
  restoreGridState() {
    const { colState, filterState, pivotState, searchKey } =
      this.state.payTypesGrid;
    if (colState && this.gridApi && this.gridColumnApi) {
      this.gridColumnApi.applyColumnState(colState);
      this.gridColumnApi.setPivotMode(pivotState);
      // this.gridApi.setSortModel(sortState);
      this.assignColumnState(colState);
      this.gridApi.setFilterModel(filterState);
      this.setState(
        {
          searchKey
        },
        () => {
          this.gridApi.setQuickFilter(searchKey);
        }
      );
    }
  }
  onFirstDataRendered() {
    this.restoreGridState();
    this.sizeToFit();
  }
  handleColumnResized = () => {
    // this.gridApi.resetRowHeights();
  };
  /* IMP - this function required for CRUD operations, to get RowNode */
  getRowNodeId(data) {
    return data.dealerServiceTypeId; // primary or unique key of record
  }
  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.loadGridData();
    this.gridApi.closeToolPanel();
    this.applySortConfig();
  };
  sizeToFit() {
    this.gridApi && this.gridApi.sizeColumnsToFit();
  }
  handleGridSizeChanged = event => {
    const { clientWidth, clientHeight } = event;
    if (clientWidth && clientHeight) {
      this.sizeToFit();
    }
  };
  updateState(data) {
    if (data) {
      const datalist = Array.isArray(data) ? data : [];
      if (datalist.length === 0) {
        this.gridApi && this.gridApi.showNoRowsOverlay();
      } else {
        this.gridApi && this.gridApi.hideOverlay();
      }
      this.setState(
        {
          serviceTypes: datalist
        },
        () => {
          this.sizeToFit();
        }
      );
    }
  }
  loadGridData() {
    const { dealerCode } = this.context;
    const params = { dealerCode };
    const restUrl = "/ops/operations/getDealerServiceTypes";
    // const restUrl = "/ops/rest-db/getValues/paytypes";
    // const headers = {
    //   Accept: "application/json",
    //   "Content-Type": "application/json"
    // };
    showBodyMask();
    makeSecureRestApi(
      {
        url: restUrl,
        method: "get",
        params
        // headers
      },
      data => {
        if (data) {
          // Add Default filter to show only Calculated pricing workflow supported records.
          hideBodyMask();
          this.gridApi && this.gridApi.hideOverlay();
          this.updateState(data.serviceTypes);
        }
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "There was an error while fetching data for this dealer.  Please try again later.";
        toast.error(msg, {
          closeOnClick: true
        });
        this.gridApi && this.gridApi.showNoRowsOverlay();
        hideBodyMask();
      }
    );
  }

  applySortConfig() {
    const defaultSortModel = [
      {
        colId: "make",
        sortIndex: 0,
        sort: "asc"
      },
      {
        colId: "name",
        sortIndex: 1,
        sort: "asc"
      }
    ];
    // this.gridApi && this.gridApi.setSortModel(defaultSortModel);
    this.assignColumnState(defaultSortModel);
  }
  assignColumnState = defaultSortModel => {
    this.gridColumnApi &&
      this.gridColumnApi.applyColumnState({
        state: defaultSortModel,
        defaultState: {
          // important to say 'null' as undefined means 'do nothing'
          sort: null
        }
      });
  };
  callRefreshAfterMillis(params, gridApi) {
    setTimeout(function () {
      gridApi.refreshCells(params);
    }, 300);
  }
  /* "cellClicked" event handler fired on specific columns */

  onCellClickedEvent(params) {
    const field = params.colDef.field;
    const { serviceTypeCode, description } = params.data;
    if (
      field === "" &&
      serviceTypeCode !== "MR" &&
      description !== "Mechanical Repair"
    ) {
      const record = params.data;
      this.setState(prevState => ({
        showDeleteConfirmation: !prevState.showDeleteConfirmation,
        rowRecord: record
      }));
    }
  }
  // This event fired after a cell has been changed with default editing
  onCellValueChanged(params) {
    if (
      toEmptyStringIfUndefined(params.oldValue) !==
      toEmptyStringIfUndefined(params.newValue)
    ) {
      if (this.validateField(params)) {
        this.clearFieldValidation(params);
        this.onSaveCellEdit(params);
      }
    }
  }

  handleSaveCellEdit = event => {
    event.preventDefault();
    const { data, field, value } = event.detail;
    this.savePayload({ data, colDef: { field } }, value);
  };
  onSaveCellEdit(params) {
    this.savePayload(params, params.value);
  }
  /* celledit handler to save edits */
  savePayload(params, value, suppressStatus) {
    if (!suppressStatus) this.updateStatusBox(this.savingMsg, "pending", false);
    const { user } = this.context;
    const { userName } = user;
    const { data, colDef } = params;
    const { field } = colDef;
    const url = `/ops/proxyapi/ddsproxy/rest/table/dealerServiceType/${data.dealerServiceTypeId}?_method=put`;
    // const url = "/ops/rest-db/updateTableData/paytypes";
    const payload = {
      // dealerPayTypeId: data.dealerPayTypeId,
      [field]: value,
      modUser: userName
    };
    makeSecureRestApi(
      {
        url,
        method: "post",
        data: payload
      },
      () => {
        if (!suppressStatus)
          this.updateStatusBox(this.savedMsg, "success", true);
        this.refreshCell(data, field);
      },
      error => {
        const errMsg = error["message"]
          ? error.message
          : xlate("xmm.portal.errors.save_data_error");
        if (!suppressStatus) this.updateStatusBox(errMsg, "error", false, true);
      }
    );
  }
  confirmDeletePayType = dealerPayType => {
    this.setState({ dealerPayType, showDeleteConfirmation: true });
  };
  handleDeleteServiceTypes(serviceType) {
    this.updateStatusBox(this.deletingMsg, "pending", false);
    if (serviceType) {
      const { dealerCode, dealerServiceTypeId, serviceTypeCode } = serviceType;
      const params = { dealerCode, dealerServiceTypeId, serviceTypeCode };
      const url = "/ops/operations/deleteDealerServiceType";
      makeSecureRestApi(
        {
          url,
          method: "get",
          params
        },
        () => {
          const res = this.gridApi.applyTransaction({
            remove: [serviceType]
          });
          res.remove.forEach(function (rowNode) {
            console.log("Removed Row Node", rowNode);
          });
          this.updateStatusBox(this.deletedMsg, "success", true);
          this.setState({ showDeleteConfirmation: false });
        },
        error => {
          this.setState({ showDeleteConfirmation: false });
          const msg = error["message"] ? error.message : this.deletingErrorMsg;
          this.updateStatusBox(msg, "error", false, true);
        }
      );
    }
  }
  getColumnList(localeStrings) {
    const baseCols = [
      {
        headerName: localeStrings["xmm.portal.nav.service_types"],
        children: [
          {
            headerName: localeStrings["xmm.portal.service_types.code"],
            headerClass: "ag-text-header",
            editable: false,
            cellEditor: TextEditor,
            cellEditorParams: {
              maxLength: 3
            },
            cellClass: "xmm-wrap-cell",
            field: "serviceTypeCode",
            sortingOrder: ["asc", "desc"],
            maxWidth: 150,
            minWidth: 100,
            filter: "agSetColumnFilter",
            suppressSizeToFit: true,
            filterParams: {
              buttons: ["clear"]
            }
          },
          {
            headerName: localeStrings["xmm.portal.service_types.description"],
            field: "description",
            // eslint-disable-next-line no-constant-condition
            editable: false,
            cellEditor: TextEditor,
            cellClass: "editable-cell",
            minWidth: 120,
            sortingOrder: ["asc", "desc"],
            filter: "agSetColumnFilter",
            filterParams: {
              buttons: ["clear"]
            }
          },
          {
            headerName: "",
            field: "",
            cellRenderer: this.renderDeleteButton,
            suppressClickEdit: true,
            enableRowGroup: false,
            maxWidth: 61,
            minWidth: 61,
            width: 61,
            filter: false,
            suppressSizeToFit: true,
            suppressMenu: true,
            suppressColumnsToolPanel: true
          }
        ]
      }
    ];
    return baseCols;
  }
  setFieldValidation(operation, field, errorKey) {
    const { localeStrings } = this.context;
    if (!operation.errors) {
      operation.errors = {};
    }
    operation.errors[field] = localeStrings[errorKey];
    this.refreshCell(operation, field);
    this.updateStatusBox(operation.errors[field], "error", false, true);
  }
  refreshCell(record, field) {
    const rowNode =
      this.gridApi && this.gridApi.getRowNode(record.dealerPayTypeId);
    if (rowNode) {
      const params = {
        // don't do force since cell would be flashed as well
        // force: true,
        columns: [field],
        rowNodes: [rowNode]
      };
      this.gridApi.refreshCells(params);
    }
  }
  clearFieldValidation(params) {
    const { field } = params.colDef;
    const operation = params.data;
    if (operation && operation.errors && operation.errors[field]) {
      operation.errors[field] = "";
    }
  }
  validateField(params) {
    const { colDef, data, newValue } = params;
    const field = colDef ? colDef.field : null;
    let errorKey = null;
    if (data && field) {
      switch (field) {
        case "payCode":
        case "description":
          if (
            doesEmpty(newValue) ||
            formvalidator.validateAlphaNumPunctuation(newValue)
          ) {
            errorKey = "xmm.portal.errors.invalid_field";
          }
          break;
        default:
          break;
      }
    }
    if (errorKey) {
      this.setFieldValidation(data, field, errorKey);
    }
    return !errorKey;
  }

  // bind to add rate click event
  addActionSlider = event => {
    event.preventDefault();
    this.setState(prevState => ({
      showSlide: !prevState.showSlide,
      editOption: "add",
      rowRecord: ServiceTypesRecord,
      operationName: this.addServiceTypesLabel
    }));
  };
  addOptionSlider = event => {
    event.preventDefault();
    this.setState(prevState => ({
      showSlide: !prevState.showSlide,
      editOption: "add",
      rowRecord: ServiceTypesRecord,
      operationName: this.option
    }));
  };
  handleRowSelected = () => {};

  /* This selection handler returns selected records from grid */
  handleSelectionChanged = () => {
    if (this.gridApi) {
      const selectedRows = this.gridApi.getSelectedRows();
      this.setState({ selectionlist: selectedRows });
    }
  };
  hideSlider = () => {
    this.setState(prevState => ({
      showSlide: !prevState.showSlide,
      editOption: "cancel",
      rowRecord: ServiceTypesRecord,
      operationName: this.addServiceTypesLabel
    }));
  };
  /* Close Handler for Slider and click event outside the drawer
     use this context callback to show speed bump when Form is dirty
  */
  closeSlider = () => {
    const callback = () => {
      this.setState({
        showSlide: false
      });
    };
    const isDirtyFunc = () => {
      return this.payTypeFormRef.current.state.dirty;
    };
    if (this.context.discardUnsavedChanges({}, callback, isDirtyFunc)) {
      this.setState({
        showSlide: false
      });
    }
  };
  updateServiceTypesAfterSave = serviceType => {
    if (this.gridApi && serviceType) {
      const res = this.gridApi.applyTransaction({
        add: [serviceType]
      });
      // get the row node with ID
      const rowNode = this.gridApi.getRowNode(serviceType.dealerServiceTypeId);
      this.gridApi.ensureIndexVisible(res.add[0].rowIndex, "top");
      rowNode.setSelected(false);
      const gridParams = {
        force: true,
        rowNodes: [rowNode]
      };
      const { serviceTypes } = this.state;
      serviceTypes.push(serviceType);
      this.setState({ serviceTypes });
      this.callRefreshAfterMillis(gridParams, this.gridApi);
    }
  };
  closeDeleteModal = doneDeleting => {
    if (doneDeleting) {
      this.setState({ selectionlist: [], showDeleteConfirmation: false });
    }
  };
  renderDeleteButton(params) {
    const { serviceTypeCode, description } = params.data;
    if (serviceTypeCode === "MR" && description === "Mechanical Repair") {
      return "";
    }
    return <IconDelete className="xmm-rule-dropdown-menu-renderer" />;
  }
  renderDeletePayTypeConfirmation() {
    const { rowRecord, showDeleteConfirmation } = this.state;
    const { localeStrings } = this.context;
    const { operationCount, serviceTypeCode, description } = rowRecord;
    let msg = localeStrings["xmm.portal.service_type.delete_confirmation"];
    const isDefault =
      serviceTypeCode === "MR" && description === "Mechanical Repair";
    if (operationCount) {
      msg =
        localeStrings["xmm.portal.service_type.delete_confirmation_associated"];
      msg = msg.replace("N", operationCount);
    }

    return showDeleteConfirmation && !isDefault ? (
      <Confirmation
        htmlId="deleteMenuType"
        message={msg}
        proceedButtonStyle="danger"
        show={showDeleteConfirmation}
        actionFunction={() => {
          this.handleDeleteServiceTypes(rowRecord);
        }}
        closeDialog={this.closeDeleteModal}
      />
    ) : (
      ""
    );
  }

  /* Handler to update statusbox state props */
  updateStatusBox(msg, type, close, errorInTooltip) {
    console.log("status", msg, type, close);
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
    sleep(0).then(() => {
      this.setState({
        statusMsg: msg,
        autoClose: close,
        statusType: type,
        errorInTooltip
      });
    });
  }
  render() {
    const deleteModal = this.renderDeletePayTypeConfirmation();
    const { localeStrings } = this.context;
    const gridWidget = (
      <div id="grid-wrapper">
        <div
          id="mainGrid"
          className="ag-grid-container ag-theme-balham xmm-info-note"
        >
          <AgGridReact
            localeText={this.state.localeText}
            columnDefs={this.state.columnDefs}
            defaultColDef={this.state.defaultColDef}
            suppressRowClickSelection={true}
            suppressMenuHide={false}
            suppressContextMenu={true}
            rowData={this.state.serviceTypes}
            rowSelection={this.state.rowSelection}
            rowDeselection={true}
            singleClickEdit={true}
            stopEditingWhenCellsLoseFocus={true}
            animateRows={true}
            onGridReady={this.onGridReady}
            onGridSizeChanged={this.handleGridSizeChanged}
            loadingOverlayComponent={this.state.loadingOverlayComponent}
            loadingOverlayComponentParams={
              this.state.loadingOverlayComponentParams
            }
            noRowsOverlayComponent={this.state.noRowsOverlayComponent}
            noRowsOverlayComponentParams={
              this.state.noRowsOverlayComponentParams
            }
            statusBar={this.state.statusBar}
            components={this.state.components}
            onCellClicked={this.onCellClickedEvent}
            onCellValueChanged={this.onCellValueChanged}
            onColumnResized={this.handleColumnResized}
            getRowNodeId={this.getRowNodeId}
            onRowSelected={this.handleRowSelected}
            onSelectionChanged={this.handleSelectionChanged}
            sideBar={this.state.sideBar}
            columnTypes={this.state.columnTypes}
            multiSortKey={this.state.multiSortKey}
            enableRangeSelection={false}
            enableCellTextSelection={true}
            enableBrowserTooltips={true}
            // enterMovesDownAfterEdit={true}
            // enterMovesDown={true}
            // rowHeight={50}
            onFirstDataRendered={this.onFirstDataRendered}
          />
        </div>
        <div className="xmm-help-text">
          {localeStrings["xmm.portal.service_type.info_note"]}
        </div>
      </div>
    );
    const statusHtml = this.state.statusMsg ? (
      <StatusBox
        htmlId="statusBox"
        type={this.state.statusType}
        autoClose={this.state.autoClose}
        linkHtml={null}
        message={this.state.statusMsg}
        // autoCloseTime={1500}
        errorInTooltip={this.state.errorInTooltip}
      />
    ) : (
      ""
    );
    const contentInfoPopover = (
      <div>{localeStrings["xmm.portal.service_type.delete_popover_info"]}</div>
    );
    const header = (
      <React.Fragment>
        <div className="content-header">
          <h3 className="xmm-main-title">{this.state.pageTitle}</h3>
          <div className="xmm-form-header">
            <div className="float-right">{statusHtml}</div>
            <Button
              htmlId="addActionBtn"
              buttonStyle="secondary"
              disabled={this.state.disableAction}
              onClick={this.addActionSlider}
              className="float-right"
            >
              {this.addServiceTypesLabel}
            </Button>
            <Popover
              htmlId="popoverTitleDiscount"
              popoverContent={contentInfoPopover}
              trigger={["click", "outsideClick"]}
            >
              <IconInfoOutline
                htmlId="popoverTitleDiscountInfo"
                className="hand-cursor"
              />
            </Popover>
          </div>
        </div>
      </React.Fragment>
    );
    // Add components within slider
    const modalTitle = <span>{this.state.operationName}</span>;
    const formSlider = (
      <GenericSlider
        title={modalTitle}
        htmlId="editCommonSlider"
        showSlide={this.state.showSlide}
        toggleSlider={this.closeSlider}
        sliderWidth={this.state.sliderWidth}
        flexWidth={this.state.flexWidth}
      >
        <div>
          <AddServiceTypesFormPage
            key={"editSlider"}
            ref={this.payTypeFormRef}
            rowRecord={this.state.rowRecord}
            editForm={true}
            serviceTypes={this.state.serviceTypes}
            editOption={this.state.editOption}
            hideSlider={this.hideSlider}
            updateServiceTypesAfterSave={this.updateServiceTypesAfterSave}
          />
        </div>
      </GenericSlider>
    );
    return (
      <div className="xmm-center-container">
        {header}
        {gridWidget}
        {formSlider}
        {deleteModal}
      </div>
    );
  }
}

export default ServiceTypesPage;

ServiceTypesPage.propTypes = {};
