/* eslint-dilocalPriceFormattersable no-console */
import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import { AppContext } from "../../../../components/app-context";
import CustomLoadingOverlay from "../../../../commonUtil/components/loadingmask/CustomLoadingOverlay";
import Col from "@cx/ui/Col";
import Row from "@cx/ui/Row";
import SelectInput from "@cx/ui/SelectInput";
import Button from "@cx/ui/Button";
import GenericSlider from "../../../../commonUtil/components/GenericSlider";
import Popover from "@cx/ui/Popover";
import IconInfoOutline from "@cx/ui/Icons/IconInfoOutline";
import { doesEmpty } from "../../../../commonUtil/utils/object";
import { applyCustomKeyNavigation } from "../../../../commonUtil/utils/keyNavigation";
import { toEmptyStringIfUndefined } from "../../../../commonUtil/utils/string";
import CheckboxCell from "../../../../commonUtil/renders/CheckboxCell";
import PriceEditor from "../../../../commonUtil/editors/PriceEditor";
import { priceFormatter } from "../../../../commonUtil/utils/formatter";
import IconModeEdit from "@cx/ui/Icons/IconModeEdit";
import IconMore from "@cx/ui/Icons/IconMore";
import Badge from "@cx/ui/Badge";
import ModalDialog from "@cx/ui/ModalDialog";
import LaborGridEditPage from "./content/LaborGridEditPage";
import CustomInfoHeader from "./content/CustomInfoHeader";
import Confirmation from "../../../../commonUtil/dialog/Confirmation";
import LaborPricingEditor from "./content/LaborPricingEditor";
import DirtyCheckPopup from "../../../reusable/modals/DirtyCheckPopup";
import AddLaborPricingFormPage from "./content/AddLaborPricingFormPage";
import {
  makeSecureRestApi,
  showBodyMask,
  hideBodyMask
} from "../../../../api/xmmAxios";
import { LaborPricingRuleRecord, LaborPricingRuleRecordV2 } from "../../../../constants/ModuleConstants";
import { toast } from "@cx/ui/Toast";
import { loadAgGridLocale } from "../../../../i18n/LocaleSender";
import { xlate } from "../../../../commonUtil/i18n/locales";
import StatusBox from "../../../../commonUtil/components/templates/StatusBox";
import { getCellClassRule } from "../../../../commonUtil/utils/validation";
import * as formvalidator from "../../../formik/formvalidator";
import { isNull } from "lodash";
import {isUserCreatedRule} from "./content/utils";

class ServiceTypesPage extends Component {
  static contextType = AppContext;

  constructor(props, context) {
    super(props, context);

    this.laborRateFormRef = React.createRef();
    this.laborRateFormRefModal = React.createRef();

    // Bind grid functions in constructor
    this.getRowNodeId = this.getRowNodeId.bind(this);
    this.onCellClickedEvent = this.onCellClickedEvent.bind(this);
    this.onCellValueChanged = this.onCellValueChanged.bind(this);
    this.handleColumnResized = this.handleColumnResized.bind(this);
    // bind other external actions
    this.getMakeList = this.getMakeList.bind(this);
    this.handleShowLaborGrid = this.handleShowLaborGrid.bind(this);
    this.onSaveLaborGrid = this.onSaveLaborGrid.bind(this);
    this.laborRateRenderer = this.laborRateRenderer.bind(this);
    this.updateStatusBox = this.updateStatusBox.bind(this);
    this.onFirstDataRendered = this.onFirstDataRendered.bind(this);
    this.handleSaveCellEdit = this.handleSaveCellEdit.bind(this);
    this.handleGridSizeChanged = this.handleGridSizeChanged.bind(this);
    this.updatePricingLaborAfterSave =
      this.updatePricingLaborAfterSave.bind(this);
    this.confirmDeleteLaborPricing = this.confirmDeleteLaborPricing.bind(this);
    this.editLaborPricing = this.editLaborPricing.bind(this);
    const { dealer, localeStrings, enableDMSPlusContent, enableServiceContractV2 } = context;
    const { commonOpsLaborGridsEnabled, dmsType } = dealer;
    const isDealerTrack = dmsType === "DEALERTRACK" || enableDMSPlusContent;

    this.initializeLocaleValues();
    const validationFields = ["description", "payCode"];
    const editableFields = ["description", "payCode"];
    const checkboxFields = ["defaultPayType"];
    this.groupList = [];
    const gridOptions = {
      // other state props
      commonOpsLaborGridsEnabled,
      searchKey: context.payTypesGrid.searchKey,
      payTypesGrid: context.payTypesGrid,
      pageTitle: this.pageTitle,
      laborPricingName: "",
      editOption: null, // set values as edit, add, delete
      rowRecord: enableServiceContractV2? LaborPricingRuleRecordV2: LaborPricingRuleRecord,
      emptyRecord: enableServiceContractV2? LaborPricingRuleRecordV2: LaborPricingRuleRecord,
      rowData: null,
      sliderWidth: 500,
      flexWidth: false,
      expandWidth: true,
      showSlide: false,
      showLaborRateGridSpeedBump: false,
      isDealerTrack,
      // ag-grid props
      selectionlist: [],
      allMakeList: [],
      selectedMake: {
        make: "",
        variant: "",
        dealerCode: context.dealerCode
      },
      make: "",
      groupDisplayType: "groupRows",
      groupDefaultExpanded: 1,
      groupRowRendererParams: {
        suppressCount: true
      },
      columnDefs: this.getColumnList(localeStrings, commonOpsLaborGridsEnabled),
      defaultColDef: {
        floatingFilter: false, // true - enable column header filters
        sortable: true,
        resizable: true,
        editable: false, // default disable editor
        enableRowGroup: false,
        sortingOrder: ["asc", "desc", null],
        width: 100,
        autoHeight: true,
        filter: "agTextColumnFilter",
        cellClassRules: getCellClassRule(
          validationFields,
          editableFields,
          checkboxFields
        ),
        getQuickFilterText: params => {
          if (!params.column.visible) {
            return null;
          } else {
            return params.value;
          }
        },
        headerComponentParams: {
          template: `
          <div class="ag-cell-label-container" role="presentation">
            <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
            <div ref="eLabel" class="ag-header-cell-label" role="presentation">
              <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
              <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
              <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>
              <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>
              <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>
            </div>
          </div>
          `
        },
        suppressKeyboardEvent: applyCustomKeyNavigation,
        rowGroup: false
      },
      multiSortKey: "ctrl",
      components: {},
      loadingOverlayComponent: CustomLoadingOverlay,
      loadingOverlayComponentParams: {
        loadingMessage: "Loading",
        isLoading: true,
        noRows: false
      },
      noRowsOverlayComponent: CustomLoadingOverlay,
      noRowsOverlayComponentParams: {
        loadingMessage: "No records found.",
        noRowsMessage: this.noServiceTypesMsg,
        isLoading: false,
        noRows: true
      },
      statusBar: {
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left"
          },
          {
            statusPanel: "agFilteredRowCountComponent"
          },
          {
            statusPanel: "agSelectedRowCountComponent",
            align: "left"
          }
        ]
      },
      // true - use browser default tooltip instead of ag-grid tooltip
      enableBrowserTooltips: true,
      columnTypes: {
        numberColumn: {
          maxWidth: 130,
          minWidth: 130,
          filter: "agNumberColumnFilter",
          filterParams: {
            includeBlanksInEquals: false,
            includeBlanksInLessThan: false,
            includeBlanksInGreaterThan: false,
            buttons: ["clear"]
          }
        },
        nonEditableColumn: { editable: false },
        noFilterColumn: {
          width: 100,
          columnGroupShow: "open",
          filter: false
        }
      },
      rowSelection: "multiple",
      isRowSelectable() {
        return true; // to see checkbox
        // return rowNode.data ? rowNode.data.make !== "ANY" : false;
      },
      localeText: loadAgGridLocale(localeStrings),
      sideBar: {
        hiddenByDefault: true,
        showSlide: false
      }
    };
    this.state = gridOptions;
  }
  /**
   * Add event listeners
   * when an instance of a component is being created and inserted into the DOM
   */
  componentDidMount() {
    this.getPayTypes();
    this.getMakeList();
    window.addEventListener(
      "saveCellEditEvent",
      this.handleSaveCellEdit,
      false
    );
  }
  /**
   * Remove event listeners
   * when a component is being removed from the DOM
   */
  componentWillUnmount() {
    this.saveGridState();
    window.removeEventListener(
      "saveCellEditEvent",
      this.handleSaveCellEdit,
      false
    );
  }
  initializeLocaleValues() {
    this.option = xlate("xmm.portal.pay_types.option");
    this.pageTitle = xlate("xmm.portal.pricing_rules.labor_pricing");
    this.addLaborRateLabel = xlate("xmm.portal.pricing_rules.new_labor_rate");
    this.addLaborRateTooltip = xlate(
      "xmm.portal.pricing_rules.new_labor_rate_tooltip"
    );
    this.deletingMsg = xlate("xmm.portal.common.deleting");
    this.deletedMsg = xlate("xmm.portal.common.deleted");
    this.deletingErrorMsg = xlate("xmm.portal.errors.deleting");
    this.savingMsg = xlate("xmm.portal.common.saving");
    this.savedMsg = xlate("xmm.portal.common.saved");
    this.saveErrorMsg = xlate("xmm.portal.common.cannot_save_changes");
    this.noServiceTypesMsg = xlate("xmm.portal.pricing_rules.no_service_types_msg");
  }
  /* Action to save ag-grid {column, filter, pivot, sort} to local state
   */
  saveGridState() {
    const { searchKey } = this.state;
    if (this.gridApi && this.gridColumnApi) {
      const payTypesGrid = {
        colState: this.gridColumnApi.getColumnState(),
        pivotState: this.gridColumnApi.isPivotMode(),
        filterState: this.gridApi.getFilterModel(),
        searchKey
      };
      this.setState({
        payTypesGrid
      });
      this.context.setPayTypesGridState(payTypesGrid);
    }
  }
  /* This Util called to restore ag-grid controls,filters,sorters from app-context when re-visited page */
  restoreGridState() {
    const { colState, filterState, pivotState, searchKey } =
      this.state.payTypesGrid;
    if (colState && this.gridApi && this.gridColumnApi) {
      this.gridColumnApi.applyColumnState(colState);
      this.gridColumnApi.setPivotMode(pivotState);
      // this.gridApi.setSortModel(sortState);
      this.assignColumnState(colState);
      this.gridApi.setFilterModel(filterState);
      this.setState(
        {
          searchKey
        },
        () => {
          this.gridApi.setQuickFilter(searchKey);
        }
      );
    }
  }
  onFirstDataRendered() {
    this.restoreGridState();
    this.sizeToFit();
  }
  handleColumnResized = () => {
    // this.gridApi.resetRowHeights();
  };
  /* IMP - this function required for CRUD operations, to get RowNode */
  getRowNodeId(data) {
    return data.dealerLaborPricingRuleId; // primary or unique key of record
  }
  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.loadGridData();
    this.gridApi.closeToolPanel();
    this.applySortConfig();
  };
  sizeToFit() {
    this.gridApi && this.gridApi.sizeColumnsToFit();
  }
  handleGridSizeChanged = event => {
    const { clientWidth, clientHeight } = event;
    if (clientWidth && clientHeight) {
      this.sizeToFit();
    }
  };
  refreshGridData = () => {
    this.loadGridData();
    this.updateStatusBox("", "text", false);
  };
  updateState(data) {
    if (data) {
      const datalist = Array.isArray(data) ? data : [];
      if (datalist.length === 0) {
        this.gridApi && this.gridApi.showNoRowsOverlay();
      } else {
        this.gridApi && this.gridApi.hideOverlay();
      }
      this.setState(
        {
          rowData: datalist
        },
        () => {
          this.sizeToFit();
        }
      );
    }
  }
  getMakeList() {
    const { dealerCode } = this.context;
    const params = { dealerCode };
    const restUrl =
      "/ops/proxyapi/ddsproxy/rest/table/getEnhancedAndAnyVariantDealers";
    showBodyMask();
    makeSecureRestApi(
      {
        url: restUrl,
        method: "get",
        params
        // headers
      },
      data => {
        if (data) {
          // Add Default filter to show only Calculated pricing workflow supported records.
          hideBodyMask();
          this.setState({
            makeList: data,
            allMakeList: data.map(make => ({
              label: make.make,
              value: make.make
            }))
          });
        }
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "There was an error while fetching data for this dealer.  Please try again later.";
        toast.error(msg, {
          closeOnClick: true
        });
        hideBodyMask();
      }
    );
  }
  getPayTypes() {
    const { dealerCode } = this.context;
    const restUrl = `ops/proxyapi/oeproxy/rest/internal/xmmdealer/getDealerPayTypesByDealerType/${dealerCode}`;
    makeSecureRestApi(
      {
        url: restUrl,
        method: "get",
      },
      () => {},
      error => {
        const msg = error["message"]
          ? error.message
          : "There was an error while fetching data for this dealer.  Please try again later.";
        this.updateStatusBox(msg, "error", false, true);
      }
    );
  }
  onMakeChange = event => {
    const { name, value } = event.target;
    const { rowRecord, emptyRecord, makeList } = this.state;
    const variant = makeList.find(make => make.make === value);
    // load notifications from cache
    if (value) {
      this.setState(
        {
          [name]: value,
          showMask: false,
          rowRecord: { ...rowRecord, [name]: value, variant: variant.variant },
          emptyRecord: {
            ...emptyRecord,
            [name]: value,
            variant: variant.variant
          }
        },
        () => {
          this.loadGridData();
        }
      );
    }
  };

  loadGridData() {
    const { dealerCode } = this.context;
    const { make } = this.state;
    const params = { dealerCode, make };
    const restUrl =
      "/ops/proxyapi/ddsproxy/rest/proc/getDealerLaborPricingRules";
    // const restUrl = "/ops/rest-db/getValues/paytypes";
    // const headers = {
    //   Accept: "application/json",
    //   "Content-Type": "application/json"
    // };
    showBodyMask();
    if (make) {
      makeSecureRestApi(
        {
          url: restUrl,
          method: "get",
          params
          // headers
        },
        data => {
          if (data) {
            // Add Default filter to show only Calculated pricing workflow supported records.
            const filterData = data.filter((item)=>
              ((item.serviceType == null && item.serviceTypeCode == null & item.payType =='Service Contract') || item.payType !='Service Contract')
            );
            hideBodyMask();
            this.gridApi && this.gridApi.hideOverlay();
            this.updateState(filterData);
          }
        },
        error => {
          const msg = error["message"]
            ? error.message
            : "There was an error while fetching data for this dealer.  Please try again later.";
          toast.error(msg, {
            closeOnClick: true
          });
          this.gridApi && this.gridApi.showNoRowsOverlay();
          hideBodyMask();
        }
      );
    }
    this.gridApi && this.gridApi.hideOverlay();
  }
  closeLaborGridModal = laborGridDataChanged => {
    const callback = () => {
      this.setState({
        showLaborGrid: false
      });
    };
    const isDirtyFunc = () => {
      return laborGridDataChanged;
    };
    if (this.context.discardUnsavedChanges({}, callback, isDirtyFunc)) {
      this.setState({
        showLaborGrid: false
      });
    }
  };
  applySortConfig() {
    const defaultSortModel = [
      {
        colId: "make",
        sortIndex: 0,
        sort: "asc"
      },
      {
        colId: "name",
        sortIndex: 1,
        sort: "asc"
      }
    ];
    // this.gridApi && this.gridApi.setSortModel(defaultSortModel);
    this.assignColumnState(defaultSortModel);
  }
  assignColumnState = defaultSortModel => {
    this.gridColumnApi &&
      this.gridColumnApi.applyColumnState({
        state: defaultSortModel,
        defaultState: {
          // important to say 'null' as undefined means 'do nothing'
          sort: null
        }
      });
  };
  callRefreshAfterMillis(params, gridApi) {
    // gridApi.redrawRows(params);
    setTimeout(function () {
      gridApi.refreshCells(params);
    }, 300);
  }
  /* "cellClicked" event handler fired on specific columns */

  onCellClickedEvent(params) {
    const field = params.colDef.field;
    if (
      field === "skillAStandaloneRate" ||
      field === "skillBStandaloneRate" ||
      field === "skillCStandaloneRate" ||
      field === "skillDStandaloneRate"
    ) {
      const record = params.data;
      if (record.isLaborGrid) {
        this.handleShowLaborGrid(
          record.serviceType,
          record.make,
          record,
          params.colDef.headerName
        );
      }
    }
  }
  // This event fired after a cell has been changed with default editing
  onCellValueChanged(params) {
    if (
      toEmptyStringIfUndefined(params.oldValue) !==
      toEmptyStringIfUndefined(params.newValue)
    ) {
      if (this.validateField(params)) {
        this.clearFieldValidation(params);
        this.onSaveCellEdit(params);
      }
    }
  }

  handleShowLaborGrid = (serviceType, make, data, laborGridId) => {
    let skillLaborGridId = "";
    switch (laborGridId) {
      case "A":
        skillLaborGridId = "skillALaborGridId";
        break;
      case "B":
        skillLaborGridId = "skillBLaborGridId";
        break;
      case "C":
        skillLaborGridId = "skillCLaborGridId";
        break;
      case "D":
        skillLaborGridId = "skillDLaborGridId";
        break;
      default:
        break;
    }
    this.setState({
      showLaborGrid: true,
      dealerLaborRate: data,
      laborGridId: skillLaborGridId,
      addEditLaborGridLabel: xlate(
        "xmm.portal.labor_rates.add_edit_labor_grid_modal_title"
      ).replace("%1", `${serviceType} (${make})`)
    });
  };

  handleSaveCellEdit = event => {
    event.preventDefault();
    const { data, field, value } = event.detail;
    this.savePayload({ data, colDef: { field } }, value);
  };
  onSaveCellEdit(params) {
    this.savePayload(params, params.value);
  }
  /* celledit handler to save edits */
  onSaveLaborGrid(gridParams, value) {
    this.setState({ showLaborGrid: false });
    if (gridParams) {
      this.savePayload(gridParams, value);
    }
  }
  savePayload(params, value, suppressStatus) {
    if (!suppressStatus) this.updateStatusBox(this.savingMsg, "pending", false);
    const { user } = this.context;
    const { userName } = user;
    const { data, colDef } = params;
    const { field } = colDef;
    const url = `/ops/proxyapi/ddsproxy/rest/table/dealerLaborPricingRule/${data.dealerLaborPricingRuleId}?_method=put`;

    const payload = {
      [field]: value,
      modUser: userName
    };
    makeSecureRestApi(
      {
        url,
        method: "post",
        data: payload
      },
      response => {
        if (response) {
          const rowNode = this.gridApi.getRowNode(
            response.dealerLaborPricingRule.dealerLaborPricingRuleId
          );
          this.updateStatusBox(this.savedMsg, "success", true);
          const gridParams = {
            force: true,
            rowNodes: [rowNode]
          };
          this.callRefreshAfterMillis(gridParams, this.gridApi);
        }
      },
      error => {
        const errMsg = error["message"]
          ? error.message
          : xlate("xmm.portal.errors.save_data_error");
        if (!suppressStatus) this.updateStatusBox(errMsg, "error", false, true);
      }
    );
  }
  updatePricingLaborAfterSave = (laborPricing, updating) => {
    if (this.gridApi && laborPricing) {
      const res = updating
        ? this.gridApi.applyTransaction({
            update: [laborPricing]
          })
        : this.gridApi.applyTransaction({
            add: [laborPricing]
          });

      // get the row node with ID
      const rowNode = this.gridApi.getRowNode(
        String(laborPricing.dealerLaborPricingRuleId)
      );

      this.gridApi.ensureIndexVisible(
        updating ? res.update[0].rowIndex : res.add[0].rowIndex,
        "top"
      );
      rowNode.setSelected(false);

      const gridParams = {
        force: true,
        rowNodes: [rowNode]
      };
      const { rowData } = this.state;
      rowData.push(laborPricing);
      this.setState({ rowData });
      this.callRefreshAfterMillis(gridParams, this.gridApi);
    }
  };

  getColumnList(localeStrings) {
    const pricingSelectionTooltip = (
      <div>
        <p>
          {localeStrings["xmm.portal.pricing_rules.service_types_tooltip_1"]}
        </p>
        <p>
          {localeStrings["xmm.portal.pricing_rules.service_types_tooltip_2"]}
        </p>
        <p>
          {localeStrings["xmm.portal.pricing_rules.service_types_tooltip_3"]}
        </p>
        <p>
          {localeStrings["xmm.portal.pricing_rules.service_types_tooltip_4"]}
        </p>
      </div>
    );
    const skillRateTooltip = (
      <div>
        <p>
          {
            localeStrings[
              "xmm.portal.pricing_rules.skill_level_rates_tooltip_1"
            ]
          }
        </p>
        <p>
          {
            localeStrings[
              "xmm.portal.pricing_rules.skill_level_rates_tooltip_2"
            ]
          }
        </p>
      </div>
    );
    const baseCols = [
      {
        headerName: localeStrings["xmm.portal.pricing_rules.pricing_selection"],
        headerGroupComponent: CustomInfoHeader,
        headerGroupComponentParams: {
          tooltipContent: pricingSelectionTooltip
        },
        children: [
          {
            field: "payType",
            hide: true,
            rowGroup: true
          },
          {
            headerName: "",
            field: "laborRateDescription",
            cellClass: "editable-cell",
            minWidth: 120,
            sortingOrder: ["asc", "desc"],
            filter: "agSetColumnFilter",
            filterParams: {
              buttons: ["clear"]
            }
          }
        ]
      },
      {
        headerName: localeStrings["xmm.portal.parts_labor.grid_code"],
        headerGroupComponent: CustomInfoHeader,
        headerGroupComponentParams: {
          tooltipContent: localeStrings["xmm.portal.pricing_rules.code_tooltip"]
        },
        children: [
          {
            headerName: "",
            field: "code",
            cellClass: "editable-cell",
            minWidth: 120,
            maxWidth: 120,
            width: 120,
            sortingOrder: ["asc", "desc"],
            filter: "agSetColumnFilter",
            filterParams: {
              buttons: ["clear"]
            },
            cellRenderer: codeBadgeRender
          }
        ]
      },
      {
        headerName: localeStrings["xmm.portal.pricing_rules.skill_level_rates"],
        headerGroupComponent: CustomInfoHeader,
        headerGroupComponentParams: {
          tooltipContent: skillRateTooltip
        },
        headerClass: "ag-center-header",
        children: [
          {
            headerName: localeStrings["xmm.portal.pricing_rules.labor_grid"],
            field: "isLaborGrid",
            cellRenderer: CheckboxCell,
            cellRendererParams: {
              field: "isLaborGrid"
            },
            filter: "agSetColumnFilter",
            filterParams: { suppressMiniFilter: false, buttons: ["clear"] },
            editable: false,
            enableRowGroup: false,
            maxWidth: 100,
            width: 100
          },
          {
            headerName: "A",
            headerClass: "ag-numeric-header",
            field: "skillAStandaloneRate",
            cellRenderer: this.laborRateRenderer,
            cellEditor: PriceEditor,
            cellEditorParams: {
              parentHandle: this
            },
            editable: params => {
              const { isLaborGrid, rateType, warrantyType } = params.data;
              return (
                !isLaborGrid && !(rateType !== null || warrantyType !== null)
              );
            },
            cellClass: "editable-cell",
            cellStyle: { textAlign: "right" },
            suppressSizeToFit: true
          },
          {
            headerName: "B",
            field: "skillBStandaloneRate",
            cellRenderer: this.laborRateRenderer,
            cellEditor: PriceEditor,
            cellEditorParams: {
              parentHandle: this
            },
            editable: params => {
              const { isLaborGrid, rateType, warrantyType } = params.data;
              return (
                !isLaborGrid && !(rateType !== null || warrantyType !== null)
              );
            },
            headerClass: "ag-numeric-header",
            cellClass: "editable-cell",
            cellStyle: { textAlign: "right" },
            suppressSizeToFit: true
          },
          {
            headerName: "C",
            field: "skillCStandaloneRate",
            cellRenderer: this.laborRateRenderer,
            cellEditor: PriceEditor,
            cellEditorParams: {
              parentHandle: this
            },
            editable: params => {
              const { isLaborGrid, rateType, warrantyType } = params.data;
              return (
                !isLaborGrid && !(rateType !== null || warrantyType !== null)
              );
            },
            cellClass: "editable-cell",
            cellStyle: { textAlign: "right" },
            suppressSizeToFit: true
          },
          {
            headerName: "D",
            field: "skillDStandaloneRate",
            cellRenderer: this.laborRateRenderer,
            cellEditor: PriceEditor,
            cellEditorParams: {
              parentHandle: this
            },
            editable: params => {
              const { isLaborGrid, rateType, warrantyType } = params.data;
              return (
                !isLaborGrid && !(rateType !== null || warrantyType !== null)
              );
            },
            cellClass: "editable-cell",
            cellStyle: { textAlign: "right" },
            suppressSizeToFit: true
          }
        ]
      },
      {
        headerName: localeStrings["xmm.portal.pricing_rules.sublet_markup"],
        headerGroupComponent: CustomInfoHeader,
        headerGroupComponentParams: {
          tooltipContent:
            localeStrings["xmm.portal.pricing_rules.sublet_markup_tooltip"]
        },
        headerClass: "ag-center-header",
        children: [
          {
            headerName: localeStrings["xmm.portal.preview.vehicle.parts"],
            field: "subletMarkupParts",
            editable: true,
            cellClass: "editable-cell",
            cellStyle: { textAlign: "right" },
            valueFormatter: percentageFormatter,
            cellEditor: PriceEditor,
            cellEditorParams: {
              parentHandle: this,
              maxLength: 3,
              maxValue: 100,
              minValue: 0,
              allowDecimals: false
            },
            suppressSizeToFit: true
          },
          {
            headerName: localeStrings["xmm.portal.preview.vehicle.labor"],
            field: "subletMarkupLabor",
            editable: true,
            cellClass: "editable-cell",
            cellStyle: { textAlign: "right" },
            valueFormatter: percentageFormatter,
            cellEditor: PriceEditor,
            cellEditorParams: {
              parentHandle: this,
              maxLength: 3,
              maxValue: 100,
              minValue: 0,
              allowDecimals: false
            },
            suppressSizeToFit: true
          }
        ]
      },
      {
        headerName: "",
        children: [
          {
            headerName: "",
            field: "",
            pinned: "right",
            editable: true,
            cellClass: "editable-cell",
            cellEditorSelector() {
              return { component: LaborPricingEditor };
            },
            cellEditorParams: {
              parentHandle: this
            },
            cellEditorPopup: true,
            cellRenderer: this.renderMoreButton,
            enableRowGroup: false,
            maxWidth: 61,
            minWidth: 61,
            width: 61,
            filter: false,
            suppressSizeToFit: true,
            suppressMenu: true,
            suppressColumnsToolPanel: true
          }
        ]
      }
    ];
    return baseCols;
  }
  setFieldValidation(operation, field, errorKey) {
    const { localeStrings } = this.context;
    if (!operation.errors) {
      operation.errors = {};
    }
    operation.errors[field] = localeStrings[errorKey];
    this.refreshCell(operation, field);
    this.updateStatusBox(operation.errors[field], "error", false, true);
  }
  renderMoreButton(params) {
    /*
    Note: Only the normal dealer labor pricing rule will have data in the service_type_code and service_type columns of the t_dealer_labor_pricing_rule table.
    The special dealer labor pricing rule will always be null for these two columns.
    This is the way we are using to distinguish with the normal dealer labor pricing rule and the special dealer labor pricing rule.*/
    if (isUserCreatedRule(params.data.serviceType, params.data.serviceTypeCode)){
      return <IconMore className="xmm-rule-dropdown-menu-renderer" />;
    }
    return "";
  }

  closeDeleteModal = doneDeleting => {
    if (doneDeleting) {
      this.setState({ selectionlist: [], showDeleteConfirmation: false });
    }
  };
  confirmDeleteLaborPricing = laborPricing => {
    this.setState({ laborPricing, showDeleteConfirmation: true });
  };
  editLaborPricing = laborPricing => {
    this.setState(prevState => ({
      showSlide: !prevState.showSlide,
      editOption: "edit",
      rowRecord: laborPricing,
      laborPricingName: laborPricing.laborRateDescription
    }));
  };
  renderDeleteLaborPricingConfirmation() {
    const { localeStrings } = this.context;
    const { laborPricing, showDeleteConfirmation } = this.state;
    let msg = localeStrings["xmm.portal.pricing_rules.delete_confirmation"];
    if (laborPricing.powertrainWarranties) {
      msg =
        localeStrings["xmm.portal.pricing_rules.delete_warranty_confirmation"];
    }
    return showDeleteConfirmation ? (
      <Confirmation
        htmlId="deleteLaborPricing"
        message={msg}
        proceedButtonStyle="danger"
        show={showDeleteConfirmation}
        actionFunction={() => {
          this.deleteLaborPricing(laborPricing);
        }}
        closeDialog={this.closeDeleteModal}
      />
    ) : (
      ""
    );
  }
  deleteLaborPricing = laborPricing => {
    if (laborPricing) {
      const { dealerLaborPricingRuleId } = laborPricing;
      const url = `/ops/proxyapi/ddsproxy/rest/proc/deleteDealerLaborPricingRule/?dealerLaborPricingRuleId=${dealerLaborPricingRuleId}`;
      makeSecureRestApi(
        {
          url,
          method: "get"
        },
        response => {
          if (response) {
            const res = this.gridApi.applyTransaction({
              remove: [laborPricing]
            });
            res.remove.forEach(function (rowNode) {
              console.log("Removed Row Node", rowNode);
            });
            this.updateStatusBox(this.deletedMsg, "success", true);
            this.setState({ showDeleteConfirmation: false });
          }
        },
        error => {
          this.setState({ showDeleteConfirmation: false });
          const msg = error["message"] ? error.message : this.deletingErrorMsg;
          this.updateStatusBox(msg, "error", false, true);
        }
      );
    }
  };
  refreshCell(record, field) {
    const rowNode =
      this.gridApi && this.gridApi.getRowNode(record.dealerLaborPricingRuleId);
    if (rowNode) {
      const params = {
        // don't do force since cell would be flashed as well
        // force: true,
        columns: [field],
        rowNodes: [rowNode]
      };
      this.gridApi.refreshCells(params);
    }
  }
  clearFieldValidation(params) {
    const { field } = params.colDef;
    const operation = params.data;
    if (operation && operation.errors && operation.errors[field]) {
      operation.errors[field] = "";
    }
  }
  validateField(params) {
    const { colDef, data, newValue } = params;
    const field = colDef ? colDef.field : null;
    let errorKey = null;
    if (data && field) {
      switch (field) {
        case "payCode":
        case "description":
          if (
            doesEmpty(newValue) ||
            formvalidator.validateAlphaNumPunctuation(newValue)
          ) {
            errorKey = "xmm.portal.errors.invalid_field";
          }
          break;
        default:
          break;
      }
    }
    if (errorKey) {
      this.setFieldValidation(data, field, errorKey);
    }
    return !errorKey;
  }

  handleRowSelected = () => {};

  /* This selection handler returns selected records from grid */
  handleSelectionChanged = () => {
    if (this.gridApi) {
      const selectedRows = this.gridApi.getSelectedRows();
      this.setState({ selectionlist: selectedRows });
    }
  };

  /* Handler to update statusbox state props */
  updateStatusBox(msg, type, close, errorInTooltip) {
    console.log("status", msg, type, close);
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
    sleep(0).then(() => {
      this.setState({
        statusMsg: msg,
        autoClose: close,
        statusType: type,
        errorInTooltip
      });
    });
  }
  getRange(params) {
    const { data } = params;
    const { skillAStandaloneRate, powertrainWarranties } = data;
    let values = [];
    values.push(skillAStandaloneRate);
    const powertrainWarranyFields = JSON.parse(powertrainWarranties);
    if (powertrainWarranyFields.length === 0) {
      return priceFormatter(params);
    }
    powertrainWarranyFields.forEach(powertrain => {
      values.push(powertrain.hourlyRate);
    });
    values.sort((a, b) => a - b);
    return "$" + values[0] + " - " + "$" + values.pop();
  }
  laborRateRenderer(params) {
    const { data } = params;
    const { isLaborGrid, rateType, warrantyType, powertrainWarranties } = data;
    if (!isLaborGrid) {
      if (rateType !== null || warrantyType !== null) {
        if (params.value === null) {
          return <b className="bold-text">--</b>;
        }
        if (powertrainWarranties) {
          return <span>{this.getRange(params)}</span>;
        }
      }
      return priceFormatter(params);
    }
    return (
      <div className="xmm-cell-icon-edit">
        <span>Grid</span>
        <IconModeEdit htmlId="IconEditGrid" />
      </div>
    );
  }
  addActionSlider = event => {
    const { emptyRecord } = this.state;
    event.preventDefault();
    this.setState(prevState => ({
      showSlide: !prevState.showSlide,
      editOption: "add",
      rowRecord: emptyRecord,
      laborPricingName: this.addLaborRateLabel
    }));
  };
  closeSlider = () => {
    if (this.state.laborModified) {
      this.setState({ showLaborRateGridSpeedBump: true });
    } else {
      this.setState({ showSlide: false });
    }
  };
  hideSlider = () => {
    const { emptyRecord } = this.state;
    this.setState(prevState => ({
      showSlide: !prevState.showSlide,
      editOption: "cancel",
      rowRecord: emptyRecord,
      laborModified: false,
      showLaborRateGridSpeedBump: false,
      laborPricingName: this.addLaborRateLabel
    }));
  };
  render() {
    const { localeStrings } = this.context;
    const { showLaborRateGridSpeedBump, showDeleteConfirmation } = this.state;
    const deleteModal = showDeleteConfirmation ? (
      this.renderDeleteLaborPricingConfirmation()
    ) : (
      <></>
    );
    const gridWidget = (
      <div id="grid-wrapper">
        <div
          id="mainGrid"
          className="ag-grid-container ag-theme-balham xmm-make-selector"
        >
          <AgGridReact
            localeText={this.state.localeText}
            columnDefs={this.state.columnDefs}
            defaultColDef={this.state.defaultColDef}
            suppressRowClickSelection={true}
            suppressMenuHide={false}
            suppressContextMenu={true}
            rowData={this.state.rowData}
            rowSelection={this.state.rowSelection}
            rowDeselection={true}
            singleClickEdit={true}
            stopEditingWhenCellsLoseFocus={true}
            animateRows={true}
            onGridReady={this.onGridReady}
            onGridSizeChanged={this.handleGridSizeChanged}
            loadingOverlayComponent={this.state.loadingOverlayComponent}
            loadingOverlayComponentParams={
              this.state.loadingOverlayComponentParams
            }
            noRowsOverlayComponent={this.state.noRowsOverlayComponent}
            noRowsOverlayComponentParams={
              this.state.noRowsOverlayComponentParams
            }
            statusBar={this.state.statusBar}
            components={this.state.components}
            onCellClicked={this.onCellClickedEvent}
            onCellValueChanged={this.onCellValueChanged}
            onColumnResized={this.handleColumnResized}
            getRowNodeId={this.getRowNodeId}
            onRowSelected={this.handleRowSelected}
            onSelectionChanged={this.handleSelectionChanged}
            sideBar={this.state.sideBar}
            columnTypes={this.state.columnTypes}
            multiSortKey={this.state.multiSortKey}
            enableRangeSelection={false}
            enableCellTextSelection={true}
            enableBrowserTooltips={true}
            groupDisplayType={this.state.groupDisplayType}
            groupDefaultExpanded={this.state.groupDefaultExpanded}
            groupRowRendererParams={this.state.groupRowRendererParams}
            // enterMovesDownAfterEdit={true}
            // enterMovesDown={true}
            // rowHeight={50}
            onFirstDataRendered={this.onFirstDataRendered}
          />
        </div>
      </div>
    );
    const statusHtml = this.state.statusMsg ? (
      <StatusBox
        htmlId="statusBox"
        type={this.state.statusType}
        autoClose={this.state.autoClose}
        linkHtml={null}
        message={this.state.statusMsg}
        // autoCloseTime={1500}
        errorInTooltip={this.state.errorInTooltip}
      />
    ) : (
      ""
    );
    const header = (
      <React.Fragment>
        <div>
          <h3 className="xmm-main-title">{this.state.pageTitle}</h3>
          <Row>
            <Col xs={5} md={5}>
              <div className="xmm-pricing-header">
                <SelectInput
                  htmlId="makeSelect"
                  label={localeStrings["xmm.portal.common.make_lbl"]}
                  name="make"
                  className="xmm-left-label"
                  layout="horizontal"
                  displayDeselectOption={false}
                  displayPlaceholder={false}
                  placeholder="Select"
                  onChange={this.onMakeChange}
                  options={this.state.allMakeList}
                  value={this.state.make}
                  maxHeight={200}
                />
                <Button
                  htmlId="addActionBtn"
                  buttonStyle="secondary"
                  disabled={!this.state.make}
                  onClick={this.addActionSlider}
                >
                  {this.addLaborRateLabel}
                </Button>
                <Popover
                  htmlId="popoverLaborPricing"
                  popoverContent={this.addLaborRateTooltip}
                  trigger={["click", "outsideClick"]}
                  position="right"
                >
                  <IconInfoOutline
                    htmlId="laborPricingInfo"
                    className="hand-cursor"
                  />
                </Popover>
              </div>
            </Col>
            <Col xs={7} md={7}>
              <div className="float-right">{statusHtml}</div>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
    const laborGridModalTitle = <span>{this.state.addEditLaborGridLabel}</span>;
    const laborGridModal = (
      <ModalDialog
        htmlId="add-edit-labor-grid"
        show={this.state.showLaborGrid}
        backdrop={"static"}
        animation={false}
        className="xmm-add-edit-labor-grid-modal"
        header={<ModalDialog.Title>{laborGridModalTitle}</ModalDialog.Title>}
        onHide={() => {
          this.closeLaborGridModal(
            this.laborRateFormRefModal.current.isLaborGridDataChanged()
          );
        }}
        footer={false}
      >
        <div>
          <LaborGridEditPage
            key={"editSlider"}
            ref={this.laborRateFormRefModal}
            dealerLaborRate={this.state.dealerLaborRate}
            laborGridId={this.state.laborGridId}
            onCancel={this.closeLaborGridModal}
            onSaveLaborGrid={this.onSaveLaborGrid}
          />
        </div>
      </ModalDialog>
    );
    const modalTitle = <span>{this.state.laborPricingName}</span>;
    const formSlider = (
      <GenericSlider
        title={modalTitle}
        htmlId="editCommonSlider"
        showSlide={this.state.showSlide}
        toggleSlider={this.closeSlider}
        sliderWidth={this.state.sliderWidth}
        flexWidth={this.state.flexWidth}
        expandSlider={this.state.expandWidth}
      >
        <div>
          <AddLaborPricingFormPage
            key={"editSlider"}
            ref={this.payTypeFormRef}
            rowRecord={this.state.rowRecord}
            hideSlider={this.hideSlider}
            updatePricingLaborAfterSave={this.updatePricingLaborAfterSave}
            onModified={() => this.setState({ laborModified: true })}
          />
        </div>
      </GenericSlider>
    );
    const dirtyPopup = (
      <DirtyCheckPopup
        showDirtyModal={showLaborRateGridSpeedBump}
        title={xlate("xmm.portal.speed_bump.title")}
        message={xlate("xmm.portal.speed_bump.message")}
        okText={xlate("xmm.portal.speed_bump.discard")}
        cancelText={xlate("xmm.portal.speed_bump.stay_on_page")}
        cancelAction={() =>
          this.setState({ showLaborRateGridSpeedBump: false })
        }
        discardAction={() =>
          this.setState({ showSlide: false, laborModified: false })
        }
      />
    );
    return (
      <div className="xmm-center-container">
        {header}
        {gridWidget}
        {laborGridModal}
        {formSlider}
        {dirtyPopup}
        {deleteModal}
      </div>
    );
  }
}
function percentageFormatter(params) {
  if (params.value === null || params.value === "") return "";

  if (params.data) {
    return `${params.value}%`;
  }
  return "";
}
function codeBadgeRender(params) {
  return <Badge htmlId="badgeDefault">{params.value}</Badge>;
}
export default ServiceTypesPage;

ServiceTypesPage.propTypes = {};
